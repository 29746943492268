import styled from "@emotion/native";

export const ModalContainer = styled.View(({ theme }) => ({
    justifyContent: 'space-between',
    alignContent: 'flex-start',
    backgroundColor: 'white',
    minHeight: '20%',
    maxHeight: '90%',
    borderRadius: 15,
    padding: theme.sizes.md,
  }))
  