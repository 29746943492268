import React from 'react'
import { ImageStyle, StyleProp } from 'react-native'
import { FastImageWrapper } from './styles'

export interface ResourceImageProps {
  source: any
  width?: number
  height?: number
  aspectRatio: number
  placeHolder?: boolean
  borderRadius?: number
  style?: StyleProp<ImageStyle>
}

const ResourceImage: React.FC<ResourceImageProps> = ({
  source,
  width,
  height,
  aspectRatio,
  style,
  placeHolder,
  borderRadius,
}) => {

  return (
    <FastImageWrapper
      testID="ResourceImage"
      source={{ ...source }}
      style={[{ width: width, aspectRatio, borderRadius }, style]}
      resizeMode="contain"
      placeHolder={placeHolder}
    />
  )
}

export default ResourceImage
