import React, { FC, ReactNode } from 'react';
import {
  Container,
  Header,
  TextContainer,
  FormsContainer,
  ActionsContainer,
  ButtonContainer,
  InputContainer,
  MainWrapper,
  IconButton,
  RegisterText,
} from './styles'
import { Keyboard, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import { useLogin } from '@eassen/business-logic'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { useDimensions } from '@eassen/themes';
import { FormikErrors, FormikHandlers, FormikValues } from 'formik';
import Text from '../../atoms/Text';
import Input from '../../molecules/Input';
import Button from '../../molecules/Button';

interface LoginScreenTemplateProps {
  envelopeIcon: React.JSX.Element;
  lockIcon: React.JSX.Element;
  onForgotPasswordPress: () => void;
  formValues: {
    values: FormikValues
    errors: FormikErrors<FormikValues>
    handleChange: FormikHandlers['handleChange']
    handleBlur: FormikHandlers['handleBlur']
    handleSubmit: FormikHandlers['handleSubmit']
    onFocus: () => void
  }
  apiError?: any
  onSignUpPress: () => void
  isSafe?: boolean
}

const LoginScreenTemplate: FC<LoginScreenTemplateProps> = ({
  envelopeIcon,
  lockIcon,
  onForgotPasswordPress,
  formValues: { values, errors, handleChange, handleBlur, handleSubmit, onFocus },
  apiError,
  onSignUpPress,
  isSafe = false
}) => {

  const { t } = useTranslation()
  const theme = useTheme()
  const {screenType} = useDimensions()

  return (
    <MainWrapper screenType={screenType} isSafe={isSafe}>
      <Container scrollEnabled enableOnAndroid resetScrollToCoords={{ x: 0, y: 0 }} screenType={screenType}>
        <Header>
          {/* <AppLogo width={scale(300)} height={scale(80)} /> */}
          <TextContainer>
            <Text type="H1B" fontWeight="bold">
              {t('login.title')}
            </Text>
            <Text type="H3">{t('login.message')}</Text>
          </TextContainer>
        </Header>
        <FormsContainer>
          <InputContainer>
            <Input
              icon={envelopeIcon}
              inputProps={{
                value: values.email,
                onChangeText: handleChange('email'),
                onBlur: handleBlur('email'),
                onFocus,
                placeholder: 'email@address.com',
                textContentType: 'emailAddress',
                inputMode: 'email',
                keyboardType: 'email-address',
              }}
            />
            {errors.email && (
              <Text type="small" color="error">
                {errors.email.toString()}
              </Text>
            )}
          </InputContainer>
          <InputContainer>
            <Input
              icon={lockIcon}
              inputProps={{
                value: values.password,
                onChangeText: handleChange('password'),
                onBlur: handleBlur('password'),
                secureTextEntry: true,
                onFocus,
                placeholder: '********',
                textContentType: 'password',
              }}
            />
            {errors.password && (
              <Text type="small" color="error">
                {errors.password.toString()}
              </Text>
            )}
            {apiError && (
              <Text type="small" color="error">
                {apiError}
              </Text>
            )}
          </InputContainer>
          <ActionsContainer>
            <RegisterText>
              <Text type="caption" color="foreground1">
                {t('login.dont.have.account')}
              </Text>
              <TouchableOpacity onPress={onSignUpPress}>
                <Text type="caption" color="tertiary">
                  {t('login.signUpLinkLabel')}
                </Text>
              </TouchableOpacity>
            </RegisterText>
            <TouchableOpacity onPress={onForgotPasswordPress}>
              <Text type="caption" color="tertiary">
                {t('login.forgotPasswordLinkLabel')}
              </Text>
            </TouchableOpacity>
          </ActionsContainer>
        </FormsContainer>
      </Container>
      <ButtonContainer>
        <Button
          title={t('login.submitButtonLabel')}
          size="block"
          textType="cta_large"
          textColor="foreground0"
          borderRadius={8}
          color="primary"
          onPress={handleSubmit}
        />
      </ButtonContainer>
    </MainWrapper>
  )
} 

export default LoginScreenTemplate;