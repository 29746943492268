import { baseApi } from '../base';
var baseUrl = 'https://idgbwy55ac.execute-api.us-east-1.amazonaws.com/';
export var catalogApi = baseApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getMenu: builder.mutation({
            query: function (body) { return ({
                url: "".concat(baseUrl, "menu/").concat(body.restaurantId),
                method: 'GET',
            }); },
        }),
        getItem: builder.mutation({
            query: function (body) { return ({
                url: "".concat(baseUrl, "items/").concat(body.itemId),
                method: 'GET',
            }); },
        }),
        searchItem: builder.mutation({
            query: function (body) { return ({
                url: "".concat(baseUrl, "search/item/").concat(body.menuId),
                method: 'GET',
                params: {
                    search: body.search,
                },
            }); },
        }),
    }); },
});
export var useGetMenuMutation = catalogApi.useGetMenuMutation, useGetItemMutation = catalogApi.useGetItemMutation, useSearchItemMutation = catalogApi.useSearchItemMutation;
