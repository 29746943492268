import React from 'react'
import { HeaderContainer, HeaderText, IconButton } from './styles'
import { ReactComponent as BackArrow } from '../../../../../../assets/icons/backArrow.svg'
import { useTheme } from '@emotion/react'

export interface BigHeaderProps {
  text: string
  onBackPress: () => void
}

const BigHeader: React.FC<BigHeaderProps> = ({ text, onBackPress }) => {
  const theme = useTheme()

  return (
    <HeaderContainer>
      <IconButton onPress={onBackPress}>
        <BackArrow width={theme.scale(20)} height={theme.scale(20)} />
      </IconButton>
      <HeaderText type="H1B">{text}</HeaderText>
    </HeaderContainer>
  )
}

export default BigHeader
