import styled from '@emotion/native'
import { Text } from '@eassen/components'

export const HeaderContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}))

export const HeaderText = styled(Text)(({ theme }) => ({
  paddingHorizontal: theme.sizes.xs,
  textAlign: 'center',
}))

export const IconButton = styled.TouchableOpacity(({ theme }) => ({
  paddingRight: theme.sizes.sm,
  paddingVertical: theme.sizes.sm,
  position: 'absolute',
  left: 0,
}))
