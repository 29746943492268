import React, { useMemo } from 'react'
import { View, I18nManager, StyleProp, ViewStyle } from 'react-native'
import { SvgProps } from 'react-native-svg'

export interface Svgprops extends SvgProps {
  Icon: React.FC<SvgProps>
  isRTL?: 'RTL' | 'LTR' | 'DEFAULT'
  viewStyle?: StyleProp<ViewStyle>
}

const Svg: React.FC<Svgprops> = ({ Icon, isRTL = 'DEFAULT', viewStyle, ...extraProps }) => {
  const rtlEnabled = useMemo(() => {
    switch (isRTL) {
      case 'RTL':
        return true
      case 'DEFAULT':
        return I18nManager.isRTL
      default:
        return false
    }
  }, [isRTL])

  return (
    <View testID="SvgTest" style={[viewStyle, { transform: [{ rotateY: rtlEnabled ? '180deg' : '0deg' }] }]}>
      <Icon {...extraProps} />
    </View>
  )
}

export default Svg
