import React from 'react'
import { Routes, Route } from 'react-router-dom'
import LoginScreen from '../pages/Login'
import RegisterScreen from '../pages/Register'
import ForgotPasswordScreen from '../pages/ForgotPassword'

const AuthGroup: React.FC = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginScreen />} />
      <Route path="register" element={<RegisterScreen />} />
      <Route path="forgot-password" element={<ForgotPasswordScreen />} />
    </Routes>
  )
}

export default AuthGroup