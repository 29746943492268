import styled from '@emotion/native'
import { SafeAreaView } from 'react-native-safe-area-context'

export const Container = styled.View(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.background0,
}))

export const SafeContainer = styled(SafeAreaView)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.background0,
}))
