import styled from '@emotion/native'

export const PageStepperContainer = styled.View(({ theme }) => ({
  flex: 1,
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingVertical: theme.sizes.md,
  gap: theme.sizes.md,
}))

export const StepperIndicatorContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.sizes.sm,
}))

export const InputContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
}))
