import React from 'react'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { ButtonSize, ButtonWrapper, Container } from './style'
import Text from '../../atoms/Text'
import { Colors, TextSizes } from '@eassen/themes'

export interface ButtonProps {
  onPress: () => void
  title: string
  size: ButtonSize
  textType: keyof TextSizes
  textStyle?: StyleProp<TextStyle>
  activeOpacity?: number
  isWarning?: boolean
  color?: keyof Colors
  textColor?: keyof Colors
  borderRadius?: number
  borderColor?: keyof Colors
  backgroundColor?: keyof Colors
  onFocus?: () => void
  style?: StyleProp<ViewStyle>
}

const Button: React.FC<ButtonProps> = ({
  title,
  color,
  textColor,
  activeOpacity,
  textStyle,
  textType,
  size,
  borderRadius,
  onPress,
  style,
  borderColor,
  onFocus = () => {},
}) => (
  <Container style={style}>
    <ButtonWrapper
      color={color}
      borderRadius={borderRadius}
      size={size}
      onPress={onPress}
      onFocus={onFocus}
      activeOpacity={activeOpacity ? activeOpacity : 0.4}
      testID="buttonTest"
      borderColor={borderColor}
      accessibilityLabel="Press me">
      <Text type={textType} color={textColor} style={textStyle} numberOfLines={1}>
        {title}
      </Text>
    </ButtonWrapper>
  </Container>
)

export default Button
