var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { setAuthState, setLoading, useAppDispatch } from '@eassen/store';
import { useState } from 'react';
import { useLoginMutation } from '@eassen/apis';
export var useLogin = function (_a) {
    var _b = _a.onSuccess, onSuccess = _b === void 0 ? function () { } : _b;
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var _c = useState(), apiError = _c[0], setApiError = _c[1];
    var login = useLoginMutation()[0];
    var validationSchema = yup.object().shape({
        email: yup.string().email(t('error.invalid_email')).required(t('error.email_empty_error')),
        password: yup.string().required(t('error.email_empty_error')),
    });
    var formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var _c, accessToken, expiresIn, refreshToken, refreshExpiresIn, error_1;
            var _d;
            var email = _b.email, password = _b.password;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        dispatch(setLoading(true));
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, login({
                                email: email,
                                password: password,
                            }).unwrap()];
                    case 2:
                        _c = _e.sent(), accessToken = _c.accessToken, expiresIn = _c.expiresIn, refreshToken = _c.refreshToken, refreshExpiresIn = _c.refreshExpiresIn;
                        console.log(accessToken);
                        dispatch(setAuthState({
                            accessToken: accessToken,
                            accessTokenExpiresAt: expiresIn,
                            refreshToken: refreshToken,
                            refreshTokenExpiresAt: refreshExpiresIn,
                        }));
                        onSuccess();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _e.sent();
                        setApiError((_d = error_1.data) === null || _d === void 0 ? void 0 : _d.detail);
                        console.error('rejected', error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        dispatch(setLoading(false));
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); },
    });
    return { formik: formik, apiError: apiError };
};
