import React, { useCallback, useMemo } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AuthGroup from '../../modules/Auth/navigations/AuthGroup'
import { Loading } from '@eassen/components'
import { useAppInit } from '@eassen/business-logic'
import CommonGroup from '../../modules/Common/navigations/CommonGroup'
import LandingPage from '../../modules/Common/pages/Landing'

interface RootStackScreenProps {
  isLoggedIn: boolean
}

const RootStackScreen: React.FC<RootStackScreenProps> = ({ isLoggedIn = false }) => {
  const screenForAppState = useCallback(
    (isLoggedIn: boolean) => [
      isLoggedIn ? [<Route path="/auth/*" element={<AuthGroup />} key="auth"/>] : <Route path="/auth/*" element={<AuthGroup />} key="auth"/>
    ],
    [isLoggedIn],
  )

  const initialRoute = useMemo(() => {
    if (isLoggedIn) {
      return <Route path="/" element={<Navigate to="/browse" />} />
    } else {
      return <Route path="/" element={<LandingPage />} />
    }
  }, [isLoggedIn])

  return (
    <Routes>
      {initialRoute}
      {screenForAppState(isLoggedIn)}
      <Route path='/common/*' element={<CommonGroup />} />
      {/*Not found */}
      <Route path="*" element={<Navigate to="/common/not-found" />} />
      <Route path='/' element={<LandingPage />} />
    </Routes>
  )
}

const RootStackScreenMemo = React.memo(RootStackScreen, (prevProps, nextProps) => {
  return prevProps.isLoggedIn === nextProps.isLoggedIn
})

const AppNavigator: React.FC = () => {
  const { isLoading, isLoggedIn } = useAppInit()

  return (
    <Loading loading={isLoading} lottieSource={require('../../assets/lottie/loading.json')}>
      <RootStackScreenMemo isLoggedIn={isLoggedIn} />
    </Loading>
  )
}

export default AppNavigator
