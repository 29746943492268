import styled from '@emotion/native'

const Container = styled.View(() => ({
  flexDirection: 'row',
  alignItems: 'center',
}))

const IconButton = styled.TouchableOpacity(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
  paddingVertical: theme.sizes.sm,
}))

const EmptySpace = styled.View(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
  paddingVertical: theme.sizes.sm,
}))

export { Container, IconButton, EmptySpace }
