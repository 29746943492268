import styled from '@emotion/native'

export const PointsHeaderContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingVertical: 16,
}))

export const RightItemContainer = styled.TouchableOpacity(({ theme }) => ({
  paddingHorizontal: 35,
}))
