import React from 'react'
import { SvgProps } from 'react-native-svg'
import { Container, ExitWrapper } from './styles'
import Text from '../Text'
import { StyleProp, ViewStyle } from 'react-native'
import { useTheme } from '@emotion/react'

export interface ExitHeaderProps {
  ExitIcon: React.FC<SvgProps>
  title?: string
  onExit?: () => void
  style?: StyleProp<ViewStyle>
}

const ExitHeader: React.FC<ExitHeaderProps> = ({ ExitIcon, title, onExit, style }) => {
  const theme = useTheme()

  return (
    <Container style={style}>
      {title && <Text type="H3">{title}</Text>}
      <ExitWrapper onPress={onExit}>
        <ExitIcon color={theme.colors.secondary} />
      </ExitWrapper>
    </Container>
  )
}

export { ExitHeader }
