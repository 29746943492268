import styled from '@emotion/native'
import LinearGradient from 'react-native-linear-gradient'
import { Animated, StyleSheet } from 'react-native'
import Text from '../../atoms/Text'

export const HeaderImage = styled.Image({
  aspectRatio: 16 / 9,
  width: '100%',
})

export const Overlay = styled(LinearGradient)({
  ...StyleSheet.absoluteFillObject,
})

export const HeaderContainer = styled.View(({ theme }) => ({
  paddingTop: theme.sizes.sm,
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingRight: theme.sizes.sm,
  alignItems: 'center',
}))

export const Body = styled.View(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
  paddingTop: theme.sizes.xs,
  flex: 1,
}))

export const Description = styled(Text)(({ theme }) => ({
  paddingTop: theme.sizes.xxxs,
  paddingBottom: theme.sizes.sm,
}))

export const RightButton = styled.TouchableOpacity(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: 40,
  height: 40,
}))

export const TextInfoContainer = styled(Animated.View)(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
  paddingTop: theme.sizes.sm,
  backgroundColor: theme.colors.background0,
  width: '100%',
}))

// List

export const Separator = styled.View(() => ({
  height: 1,
  width: '100%',
  backgroundColor: '#6C6C6C',
  opacity: 0.1,
}))

export const TitleContainer = styled.View(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.background0,
  paddingHorizontal: theme.sizes.sm,
}))

export const SpacingView = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.background0,
}));

export const ItemContainer = styled.View(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.background0,
  paddingHorizontal: theme.sizes.sm,
}))

export const DishWrapper = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
})

export const ItemSelected = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.primary,
  width: theme.sizes.sm / 2,
  height: '90%',
  position: 'absolute',
  left: 0,
  zIndex: 1,
}))
