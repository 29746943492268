import styled from "@emotion/native"
import Button from "../Button"

export const Row = styled.View({ flexDirection: 'row' })

export const Wrapper = styled.View({ justifyContent: 'space-between', flexDirection: 'row', flex: 1 })

export const ItemDescContainer = styled.View(({ theme }) => ({
    paddingLeft: theme.sizes.xxs,
    width: '65%',
}))

export const ColumnWrapper = styled.View({
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-end',
})
export const CounterBtn = styled(Button)(({ theme, color }) => ({}))

export const CountText = styled.View(({ theme }) => ({
    paddingHorizontal: theme.sizes.xxs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))
