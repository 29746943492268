import styled from '@emotion/native'

const Container = styled.ScrollView(() => ({
  flex: 1,
}))

const HeaderImage = styled.Image(() => ({
  aspectRatio: 16 / 9,
  width: '100%',
}))

const Body = styled.View(() => ({
  padding: 16,
}))

const HeaderText = styled.View(({ theme }) => ({
  marginBottom: theme.sizes.xxs,
}))

export { Container, HeaderImage, HeaderText, Body }
