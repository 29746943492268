import styled from '@emotion/native'

export const PointsChipContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 8,
  paddingRight: 12,
  paddingLeft: 4,
  borderTopRightRadius: 50,
  borderBottomRightRadius: 50,
  backgroundColor: theme.colors.primary,
  gap: 4,
  width: 100,
}))
