var _a;
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
// import logger from 'redux-logger'
import { setupListeners } from '@reduxjs/toolkit/query';
import { appStateSlice, authStateSlice, profileSlice, deepLinkingSlice, loadingSlice, searchSlice, modalSlice, cartSlice, imageSlice, } from '../slices';
import { baseApi } from '@eassen/apis';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { listenerMiddleware } from './listenerMiddleware';
import { AddCartItemListener } from '../listeners/AddCartItem';
import { UpdateCartItemListener } from '../listeners/UpdateCartItem';
import { UpdateCartItemQuantityListener } from '../listeners/UpdateCartItemQuantity';
AddCartItemListener();
UpdateCartItemListener();
UpdateCartItemQuantityListener();
var Storage;
if (typeof window === 'undefined') {
    Storage = AsyncStorage;
}
else {
    Storage = AsyncStorage || window.localStorage;
}
var appPersistConfig = {
    key: 'app',
    storage: AsyncStorage,
};
var authPersistConfig = {
    key: 'auth',
    storage: Storage,
};
var rootReducer = combineReducers((_a = {
        appState: persistReducer(appPersistConfig, appStateSlice.reducer),
        authState: persistReducer(authPersistConfig, authStateSlice.reducer),
        profile: profileSlice.reducer,
        deeplinking: deepLinkingSlice.reducer,
        loading: loadingSlice.reducer,
        search: searchSlice.reducer,
        modal: modalSlice.reducer,
        cart: cartSlice.reducer,
        image: imageSlice.reducer
    },
    _a[baseApi.reducerPath] = baseApi.reducer,
    _a));
var isInDebugMode = function () {
    return eval('__DEV__');
};
export var store = configureStore({
    reducer: rootReducer,
    middleware: (function (getDefaultMiddleware) {
        var middleware = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(baseApi.middleware, listenerMiddleware.middleware);
        if (isInDebugMode()) {
            // middleware.push(logger as any)
        }
        return middleware;
    }),
});
setupListeners(store.dispatch);
export var persistor = persistStore(store);
