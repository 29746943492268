import { useTheme } from '@emotion/react'
import React, { useCallback } from 'react'
import { StyleProp, TextStyle, TextInputProps, ViewStyle } from 'react-native'
import { LinearGradientProps } from 'react-native-linear-gradient'
import { SvgProps } from 'react-native-svg'
import { Colors, Sizes, TextSizes } from '@eassen/themes'
import Svg from '../Svg'
import { TextProps as StyledTextProps } from '../Text/style'
import { Container, IconWrapper, InputContainer, InputWrapper, Overlay, Title } from './style'

export interface InputIcon {
  Icon: React.FC<SvgProps>
  width: number
  height: number
  color?: keyof Colors
  padding?: keyof Sizes
}

export interface InputBoxProps extends StyledTextProps, TextInputProps {
  titleType: keyof TextSizes
  titleStyle?: StyleProp<TextStyle>
  color?: keyof Colors
  title?: string
  inputTextColor?: keyof Colors
  placeholderTextColor?: keyof Colors
  LeftIcon?: InputIcon
  LeftComponent?: React.FC
  RightIcon?: InputIcon
  verticalPadding?: keyof Sizes
  horizontalPadding?: keyof Sizes
  selectionColor?: keyof Colors
  style?: StyleProp<ViewStyle>
  gradientProps?: LinearGradientProps
  focusOnMount?: boolean
  gradientStyle?: StyleProp<ViewStyle>
  backgroundColor?: keyof Colors
  getInputRef?: (_: any) => void
  rightPress?: () => void
}

const TextInput: React.FC<InputBoxProps> = ({
  title,
  titleStyle,
  color,
  type,
  inputTextColor,
  titleType,
  placeholderTextColor,
  LeftIcon,
  RightIcon,
  focusOnMount = false,
  selectionColor,
  verticalPadding,
  horizontalPadding,
  gradientProps,
  LeftComponent,
  gradientStyle,
  backgroundColor,
  style,
  getInputRef,
  rightPress,
  ...props
}) => {
  const theme = useTheme()

  const onRef = useCallback(
    (ref: any) => {
      if (ref) {
        getInputRef && getInputRef(ref)
        if (focusOnMount) {
          ref.focus()
        }
      }
    },
    [getInputRef, focusOnMount],
  )

  return (
    <Container
      backgroundColor={backgroundColor}
      testID="Input"
      verticalPadding={verticalPadding}
      horizontalPadding={horizontalPadding}
      style={style}>
      {title && (
        <Title style={titleStyle} color={color} type={titleType}>
          {title}
        </Title>
      )}
      {gradientProps && (
        <Overlay
          style={gradientStyle}
          testID="OTPInputGradient"
          colors={gradientProps.colors}
          useAngle={gradientProps.useAngle}
          angle={gradientProps.angle}
        />
      )}
      <InputContainer backgroundColor={backgroundColor}>
        {LeftIcon && (
          <IconWrapper paddingValue={LeftIcon.padding} iconSide="left" testID="LeftIcon">
            <Svg
              Icon={LeftIcon.Icon}
              width={LeftIcon.width}
              height={LeftIcon.height}
              stroke={theme.colors[LeftIcon.color ?? 'foreground0']}
            />
          </IconWrapper>
        )}
        {LeftComponent && <LeftComponent />}
        <InputWrapper
          type={type}
          ref={onRef}
          verticalPadding={verticalPadding}
          backgroundColor={backgroundColor}
          color={inputTextColor}
          selectionColor={theme.colors[selectionColor ?? 'primary'] as keyof Colors}
          placeholderTextColor={theme.colors[placeholderTextColor ?? 'background2'] as keyof Colors}
          {...props}
        />
        {RightIcon && (
          <IconWrapper paddingValue={RightIcon.padding} iconSide="right" testID="RightIcon">
            <Svg
              Icon={RightIcon.Icon}
              width={RightIcon.width}
              height={RightIcon.height}
              onPress={rightPress}
              stroke={RightIcon.color ? theme.colors[RightIcon.color ?? 'foreground0'] as keyof Colors : 'transparent'}
            />
          </IconWrapper>
        )}
      </InputContainer>
    </Container>
  )
}

export default TextInput
