import styled from "@emotion/native";

export const Container = styled.View(() => ({
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const ExitWrapper = styled.TouchableOpacity(({ theme }) => ({
    position: 'absolute',
    right: 0,
}))
