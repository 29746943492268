import React from 'react'
// import CheckBox from '@react-native-community/checkbox'
import { Container, AddOnTitle, Currency, RightWarpper, CheckBoxWrapper, CounterBtn, Row, CountText } from './styles'
import { useTheme } from '@emotion/react'
import { Colors } from '@eassen/themes'
import Text from '../Text'
import { Item as AddonInterface } from '@eassen/interfaces'
import { StyleProp, ViewStyle } from 'react-native'
import { AddonsInterface } from '../../templates/DishDetails'
import { addNestedItem, deleteNestedItem, modifyNestedItem, useAppDispatch, useAppSelector } from '@eassen/store'

export interface AddonProps extends AddonInterface {
  type?: 'Multiple' | 'Simple' | 'Quantity'
  style?: StyleProp<ViewStyle>
  metaData?: AddonsInterface['metaData']
}

export const Addon: React.FC<AddonProps> = ({ style, ...props }) => {
  const theme = useTheme()
  const showCheckBox = props.type === 'Multiple'
  const showRadioButton = props.type === 'Simple'
  const showCounter = props.type === 'Quantity'
  const item = useAppSelector((state) => state.cart.currentItems?.[props.metaData?._id ?? '']?.[props._id])
  const dispatch = useAppDispatch()

  const costantData = {
    sectionId: props.metaData?._id ?? '',
    _id: props._id,
    max_quantity: props.metaData?.max_quantity ?? 0,
    min_quantity: props.metaData?.min_quantity ?? 0,
    type: props.type,
    item_identifier: props.metaData?.item_identifier ?? '',
  }

  const onTogglePress = (value: boolean) => {
    console.log(value)
    if (value) {
      dispatch(addNestedItem({ ...costantData, quantity: 1 }))
    } else {
      dispatch(deleteNestedItem({ ...costantData }))
    }
  }

  const onQuantity = (value: 'PLUS' | 'LESS') => {
    if (value === 'PLUS') {
      if (item) {
        dispatch(modifyNestedItem({ ...costantData, quantity: item.quantity + 1 }))
      } else {
        dispatch(addNestedItem({ ...costantData, quantity: 1 }))
      }
    } else {
      if (item) {
        dispatch(modifyNestedItem({ ...costantData, quantity: item.quantity - 1 }))
      }
    }
  }
  // TODO: ADD OVERRIDE PRICE
  return (
    <Container style={style}>
      <AddOnTitle type="headline_a">{props.name}</AddOnTitle>
      <RightWarpper>
        <Currency type="small">
          {props?.price > 0 ? `${'+' + props.currency + ' ' + props.price.toFixed(2)}` : ''}
        </Currency>
        {showCheckBox && (
          <CheckBoxWrapper>
            {/* <CheckBox
              disabled={false}
              value={!!item}
              onValueChange={onTogglePress}
              boxType={'square'}
              tintColor={theme.colors.secondary as keyof Colors}
              animationDuration={0}
              offAnimationType="bounce"
              onAnimationType="bounce"
              onFillColor={theme.colors.secondary as keyof Colors}
              onTintColor={theme.colors.secondary as keyof Colors}
              onCheckColor={'#ffffff'}
            /> */}
          </CheckBoxWrapper>
        )}
        {showRadioButton && (
          <CheckBoxWrapper>
            {/* <CheckBox
              disabled={false}
              value={!!item}
              onValueChange={onTogglePress}
              boxType="circle"
              tintColor={theme.colors.secondary as keyof Colors}
              animationDuration={0}
              offAnimationType="bounce"
              onAnimationType="bounce"
              onFillColor={theme.colors.secondary as keyof Colors}
              onTintColor={theme.colors.secondary as keyof Colors}
              onCheckColor={'#ffffff'}
            /> */}
          </CheckBoxWrapper>
        )}
        {showCounter && (
          <Row>
            <CounterBtn
              onPress={() => onQuantity('LESS')}
              title="-"
              textType="cta_large"
              size="round"
              textColor={theme.colors.foreground0 as keyof Colors}
              color={theme.colors.secondary as keyof Colors}
              textStyle={{ color: theme.colors.secondary }}
              borderColor="secondary"
              borderRadius={50}
            />
            <CountText>
              <Text type="headline_b">{item?.quantity ?? '0'}</Text>
            </CountText>
            <CounterBtn
              onPress={() => onQuantity('PLUS')}
              title="+"
              textType="cta_large"
              size="round"
              textColor={theme.colors.foreground0 as keyof Colors}
              color={theme.colors.secondary as keyof Colors}
              textStyle={{ color: theme.colors.secondary }}
              borderColor="secondary"
              borderRadius={30}
            />
          </Row>
        )}
      </RightWarpper>
    </Container>
  )
}
