import React, { PropsWithChildren, useCallback, useRef } from 'react'
import { Animated, Easing, Platform, StyleProp, ViewStyle } from 'react-native'
import { ResourceContentProps } from '../ResourceContent/interfaces'
import { Container } from './style'
import { IContainer } from '../../organisms/Container'

const isIos = Platform.OS === 'ios'

export interface IResource<T = any> {
  id: string
  title: string
  description?: string
  imageUrl?: string
  aspectRatio: number
  duration?: number
  watchedOffset?: number
  nu?: string
  extraData?: T
  price?: number
  discountedPrice?: number
  currency?: string
  maxDescriptionWords?: number
  count?: number
}

export interface ResourceOnPressProps {
  resource: IResource
  container: IContainer | undefined
  resourceIndex: number
}

export interface ResourceProps<T = any> {
  value: IResource<T>
  width: number
  containerIndex: number
  resourceIndex: number
  ContentComponent: React.FC<ResourceContentProps<T>>
  onPress?: (resource: ResourceOnPressProps) => void
  imageUrlBuilder: ResourceContentProps['imageUrlBuilder']
  placeHolder?: boolean
  touchableOpacity?: number
  borderRadius?: number
  enableAnimation?: boolean
  resourceSeparatorWidth?: number
  style?: StyleProp<ViewStyle>
  cardImageStyle?: StyleProp<ViewStyle>
  useContainerDataForOnPress?: boolean
  containerData?: IContainer
}

const Resource = <T extends any>({
  ContentComponent,
  value,
  containerIndex,
  resourceIndex,
  enableAnimation = true,
  width,
  placeHolder,
  touchableOpacity,
  resourceSeparatorWidth,
  borderRadius = 0,
  onPress,
  imageUrlBuilder,
  style,
  cardImageStyle,
  containerData,
}: PropsWithChildren<ResourceProps<T>>): React.ReactElement<ResourceProps<T>> => {
  const animValue = useRef<Animated.Value>(new Animated.Value(1)).current
  const onInnerPress = useCallback(() => {
    onPress && onPress({ resource: value, container: containerData, resourceIndex })
  }, [onPress, value])

  const pressIn = useCallback(() => {
    isIos &&
      Animated.timing(animValue, {
        toValue: 0.95,
        duration: 250,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start()
  }, [animValue, enableAnimation])

  const pressOut = useCallback(() => {
    isIos &&
      Animated.timing(animValue, {
        toValue: 1,
        duration: 250,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start()
  }, [animValue, enableAnimation])

  return (
    <Container
      style={[style, { transform: [{ scale: animValue }] }]}
      borderRadius={borderRadius}
      onPress={onInnerPress}
      onPressIn={pressIn}
      onPressOut={pressOut}
      width={width}
      resourceSeparatorWidth={resourceSeparatorWidth}
      activeOpacity={touchableOpacity ?? (isIos ? 1 : 0.8)}>
      <ContentComponent
        borderRadius={borderRadius}
        value={value}
        placeHolder={placeHolder}
        containerIndex={containerIndex}
        resourceIndex={resourceIndex}
        width={width}
        imageUrlBuilder={imageUrlBuilder}
        imageViewStyle={cardImageStyle}
      />
    </Container>
  )
}

export default React.memo(Resource)
