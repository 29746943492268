import styled from '@emotion/native'

export const Container = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxxs,
}))

export const StepperIndicator = styled.View<{ active?: Boolean }>(({ theme, active }) => ({
  width: theme.sizes.xxs,
  height: theme.sizes.xxs,
  borderRadius: theme.sizes.sm,
  backgroundColor: active ? theme.colors.primary : theme.colors.background3,
  opacity: active ? 1 : 0.4,
}))
