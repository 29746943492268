import React from "react";
import BillItem, { BillItemProps } from "../../molecules/BillItem";
import { Container } from "./styles";
import { FlatList, ListRenderItem } from "react-native";

export interface BillProps {
  items: Array<BillItemProps>
}

const Bill: React.FC<BillProps> = ({ items }) => {
  const renderComponent: ListRenderItem<BillItemProps> = ({item, index}) => {
    return <BillItem {...item} key={index}/>
  }
  return (
    <FlatList
      data={items}
      renderItem={renderComponent}
    />
  )
}

export default Bill
