import styled from "@emotion/native";

const Container = styled.View(({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 30,
  gap: theme.sizes.lg,
  paddingVertical: theme.sizes.xxl,
}))

export const TitleContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxs,
  alignItems: 'center',
}))

export const ButtonContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.sizes.xs,
  alignItems: 'center',
}))

export { Container }