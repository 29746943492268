import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NotFoundScreen from '../pages/NotFound'
import LandingPage from '../pages/Landing'

const CommonGroup: React.FC = () => {
  return (
    <Routes>
      <Route path="not-found" element={<NotFoundScreen />} />
      {/*<Route path="landing" element={<LandingPage />} />*/}
    </Routes>
  )
}

export default CommonGroup