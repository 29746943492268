
import React, { useEffect, useState } from 'react';

// Definición de la estructura de los datos
interface VideoData {
    heading: string;
    headingText: string;
    videoSrc: string;
}

// Datos iniciales tipados
const initData: VideoData = {
    heading: "Conoce nuestra plataforma",
    headingText: "Aprende cómo nuestra plataforma, enfocada en desarrolladores, puede ayudarte a lograr tus metas profesionales y alcanzar una estabilidad financiera en menos de 5 meses.",
    videoSrc: "https://www.youtube.com/embed/9xwazD5SyVg?si=fqHDTUDOUmlaSbOs"
};

const VideoPlayerSection: React.FC = () => {
    // Estado tipado
    const [data, setData] = useState<VideoData | null>(null);

    useEffect(() => {
        setData(initData);
    }, []);

    return (
        <section className="section faq-area style-two ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{data?.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{data?.headingText}</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {/* Video Player */}
                    <div className="col-12 col-md-10 col-lg-8">
                        <div className="row justify-content-center">
                            {data && (
                                <iframe
                                    src={data.videoSrc}
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{
                                        width: "100%",
                                        height: "500px"
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VideoPlayerSection;
