var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {};
var deepLinkingSlice = createSlice({
    name: 'deeplinking',
    initialState: initialState,
    reducers: {
        loadDeepLinking: function (state, action) {
            state.url = action.payload.url;
        },
        loadDeepLinkinData: function (state, action) {
            state.deeplinking = action.payload;
        },
        resetDeepLinking: function () { return initialState; },
    },
});
export var loadDeepLinking = (_a = deepLinkingSlice.actions, _a.loadDeepLinking), loadDeepLinkinData = _a.loadDeepLinkinData, resetDeepLinking = _a.resetDeepLinking;
export { deepLinkingSlice };
