import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    show: false,
    body: null,
    onHide: null,
};
export var modalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        setModal: function (state, _a) {
            var payload = _a.payload;
            state.show = payload.show;
            state.body = payload.body;
            state.onHide = payload.onHide;
        },
    },
});
export var setModal = modalSlice.actions.setModal;
