var _a;
import { createSlice } from '@reduxjs/toolkit';
export var SearchMode;
(function (SearchMode) {
    SearchMode["Recommended"] = "recommended";
    SearchMode["Default"] = "default";
})(SearchMode || (SearchMode = {}));
var initialState = {
    searchTerm: '',
    searchMode: SearchMode.Recommended,
};
var searchSlice = createSlice({
    name: 'search',
    initialState: initialState,
    reducers: {
        recommendedSearch: function (state, action) {
            state.searchMode = SearchMode.Recommended;
            state.searchTerm = action.payload.searchTerm;
        },
        defaultSearch: function (state, action) {
            state.searchMode = SearchMode.Default;
            state.searchTerm = action.payload.searchTerm;
        },
        clean: function (state) {
            state.searchMode = SearchMode.Recommended;
            state.searchTerm = '';
        },
    },
});
export var recommendedSearch = (_a = searchSlice.actions, _a.recommendedSearch), defaultSearch = _a.defaultSearch, clean = _a.clean;
export { searchSlice };
