import styled from '@emotion/native'
import { ExitHeader } from '../../atoms/ExitHeader'

export const ExitHeaderWrapper = styled(ExitHeader)(({ theme }) => ({
  marginBottom: theme.sizes.md,
}))

export const PromoInputWrapper = styled.View(({ theme }) => ({
  paddingTop: theme.sizes.sm,
  paddingBottom: theme.sizes.md,
}))

export const Container = styled.View(({ theme }) => ({
  flex: 1,
  padding: theme.sizes.xs,
}))

export const PriceContainer = styled.View({ flexDirection: 'column' })

export const SubTotalContainer = styled.View({ flexDirection: 'row', justifyContent: 'space-between' })

export const Seperator = styled.View(({ theme }) => ({
  borderWidth: 1,
  borderColor: '#F1F2F3',
  marginVertical: theme.sizes.sm,
}))

export const PaymentMethodContainer = styled.View(({ theme }) => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  paddingTop: theme.sizes.xxs,
}))

export const TouchablePaymentMethod = styled.TouchableOpacity({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
})

export const PaymentMethodLeft = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.scale(10),
}))

export const PaymentMethodRight = styled.View({})
