import React, { useRef } from 'react'
import { TextInput, TextInputProps, View, ViewStyle } from 'react-native'
import { StyledLabelView, StyledInput, StyledInputContainer, StyledInputIconContainer, StyledTextInput } from './style'
import { useTheme } from '@emotion/react'
import { TextSizes } from '@eassen/themes'
import Text from '../../atoms/Text'

export interface InputProps {
  label?: string
  icon?: React.JSX.Element
  style?: ViewStyle
  textType?: keyof TextSizes
  paddingVertical?: ViewStyle['paddingVertical']
  inputProps?: TextInputProps
  borderColor?: ViewStyle['borderColor']
  RightComponent?: React.FC
}

const Input: React.FC<InputProps> = ({ label, icon, style, textType = 'paragraph_b', inputProps, borderColor, RightComponent, paddingVertical }) => {
  const theme = useTheme()
  const inputRef = useRef<TextInput>(null)

  const handleInputTouch = () => {
    inputRef.current?.focus()
  }

  return (
    <View>
      <StyledInput paddingVertical={paddingVertical} borderColor={borderColor} style={style} onTouchStart={handleInputTouch}>
        {label && <StyledLabelView>
          <Text type="caption">{label}</Text>
        </StyledLabelView>}
        <StyledInputContainer>
          {icon && <StyledInputIconContainer>{icon}</StyledInputIconContainer>}
          <StyledTextInput
            typography={textType}
            ref={inputRef}
            {...inputProps}
            placeholderTextColor="grey"
            selectionColor={theme.colors.primary}
            autoCapitalize="none"
          />
          {RightComponent && <RightComponent />}
        </StyledInputContainer>
      </StyledInput>
    </View>
  )
}

export default Input
