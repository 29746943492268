import React, { useState, useEffect } from 'react';

// Definición de la estructura de los datos
interface ServiceText {
    id: string;
    text: string;
}

interface ServiceData {
    heading: string;
    text: ServiceText[];
    thumbFive: string;
    buttonText: string;
}

// Datos iniciales tipados
const initData: ServiceData = {
    heading: "Desarrolla tu potencial y transforma tu carrera",
    text: [
        {
            id: "1",
            text: "Nuestra plataforma está diseñada para desarrolladores que desean no solo mantenerse al día en el cambiante mundo tecnológico, sino también lograr ingresos de $5k-$10k al mes en menos de 5 meses, sin necesidad de un título universitario."
        },
        {
            id: "2",
            text: "Con un enfoque en React Native, liderazgo y cómo mejorar tu CV, ofrecemos cursos prácticos y actualizados que van desde los conceptos básicos hasta herramientas avanzadas. "
        },
        {
            id: "3",
            text: "Todos los cursos son creados por expertos del sector, garantizando que aprendas las destrezas más demandadas y relevantes para impulsar tu carrera."
        }
    ],
    thumbFive: "/landingPublic/img/diploma.png",
    buttonText: "Conoce más"
};

const ServiceSection: React.FC = () => {
    // Estado tipado
    const [data, setData] = useState<ServiceData | null>(null);

    useEffect(() => {
        setData(initData);
    }, []);

    return (
        <section className="section service-area overflow-hidden ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Service Text */}
                        <div className="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                            <h2 className="text-capitalize mb-4">{data?.heading}</h2>

                            {data?.text?.map((item) => (
                                <p key={item.id} className="mb-3">{item.text}</p>
                            ))}
                            
                            <a href="/faq" className="btn sApp-btn mt-4">{data?.buttonText}</a>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                        {/* Service Thumb */}
                        <div className="service-thumb mx-auto">
                            {data && (
                                <img
                                    src={data.thumbFive}
                                    alt=""
                                    className="img-fluid w-90 d-block mx-auto mt-5"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceSection;
