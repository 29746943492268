import styled from '@emotion/native'
import { Colors } from '@eassen/themes'

export type ButtonSize = 'small' | 'medium' | 'large' | 'block' | 'round'

const Container = styled.View(() => ({
  alignItems: 'flex-start',
  overflow: 'hidden',
}))

const ButtonWrapper = styled.TouchableOpacity<{
  color: keyof Colors | undefined
  borderRadius: number | undefined
  size: ButtonSize
  borderColor: keyof Colors | undefined
}>(({ theme, color, borderRadius, size, borderColor }) => {
  const paddingVerticalMapping: Record<ButtonSize, number> = {
    small: theme.sizes.xxxs,
    medium: theme.sizes.xs,
    large: theme.sizes.sm,
    block: theme.sizes.sm,
    round: 0,
  }
  const paddingHorizontalMapping: Record<ButtonSize, number> = {
    small: theme.sizes.xxs,
    medium: theme.sizes.lg,
    large: theme.sizes.xxl,
    block: theme.sizes.xxl,
    round: 0,
  }
 
  return {
    paddingVertical: paddingVerticalMapping[size],
    paddingHorizontal: size === 'block' ? undefined : paddingHorizontalMapping[size],
    width: size === 'round' ? theme.scale(33) : size === 'block' ? '100%' : undefined,
    height: size === 'round' ? theme.scale(33) : undefined,
    maxWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: borderRadius ?? 0,
    alignSelf: 'flex-start',
    borderWidth: 1,
    flexGrow: 0,
    borderColor: borderColor ? theme.colors[borderColor] : theme.colors.primary,
    backgroundColor: color ? theme.colors[color] : 'transparent',
  }
})

export { Container, ButtonWrapper }
