import styled from '@emotion/native'
import { Platform, TextStyle } from 'react-native'
import { Colors, TextFontWeight, TextSizes, TextSizeConfig } from '@eassen/themes'

export type textAlign = 'left' | 'right' | 'center' | 'justify' | 'auto'

export interface TextProps extends Partial<TextSizeConfig> {
  type: keyof TextSizes
  color?: keyof Colors
}

const fontWeightMapping: Record<TextFontWeight, TextStyle['fontWeight']> = {
  light: '300',
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  '100': '100',
  '200': '200',
  '300': '300',
  '400': '400',
  '500': '500',
  '600': '600',
  '700': '700',
  '800': '800',
  '900': '900',
  normal: 'normal',
}

export const StyledText = styled.Text<TextProps & { textAlign?: textAlign }>(
  ({ theme, type, color, fontSize, fontWeight, fontFamily, lineHeight, letterSpacing, textAlign }) => ({
    color: theme.colors[color ?? theme.fontSizes[type]?.color] as keyof Colors,
    fontSize: fontSize ?? theme.fontSizes[type]?.fontSize,
    fontFamily: theme.fontFamilies[fontFamily ?? theme.fontSizes[type]?.fontFamily],
    fontWeight: Platform.OS === 'ios' ? fontWeightMapping[fontWeight ?? theme.fontSizes[type]?.fontWeight] : undefined,
    lineHeight: lineHeight ?? theme.fontSizes[type]?.lineHeight,
    letterSpacing: letterSpacing ?? theme.fontSizes[type]?.letterSpacing,
    textAlign: textAlign ?? 'left',
  }),
)
