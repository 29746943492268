import styled from '@emotion/native'

const SearchBoxWrapper = styled.View(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

export const BackWrapper = styled.TouchableOpacity(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
}))

export { SearchBoxWrapper }
