import styled from '@emotion/native'

export const MenuItemContainer = styled.TouchableOpacity(({ theme }) => ({
  flexDirection: 'column',
  borderRadius: 8,
  overflow: 'hidden',
  borderWidth: 1,
  borderColor: 'rgba(0, 0, 0, 0.1)',
  borderBottomWidth: 5,
  borderBottomColor: 'rgba(0, 0, 0, 0.1)',
}))

export const MenuItemInfoContainer = styled.View(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 10,
}))

export const MenuItemPriceContainer = styled.View(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  justifyContent: 'flex-end',
  marginTop: 5,
}))
