import React from 'react'
import { StyleProp, TextStyle, TouchableOpacity, ViewStyle } from 'react-native'
import { Colors } from '@eassen/themes'
import Text from '../Text'
import { TextProps as StyledTextProps } from '../Text/style'

export interface TouchableTextProps extends StyledTextProps {
  onPress: () => void
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  color?: keyof Colors
  children?: React.ReactNode
}

const TouchableText: React.FC<TouchableTextProps> = ({ children, textStyle, color, type, style, onPress }) => {
  return (
    <TouchableOpacity
      style={style}
      testID="TouchableText"
      accessibilityLabel="Press me, I'm a link text"
      onPress={onPress}>
      <Text color={color} style={textStyle} type={type}>
        {children}
      </Text>
    </TouchableOpacity>
  )
}

export default TouchableText
