var _a;
import { createSlice } from '@reduxjs/toolkit';
var authStateSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: null,
        accessTokenExpiresAt: null,
        refreshToken: null,
        refreshTokenExpiresAt: null,
    },
    reducers: {
        setAuthState: function (state, _a) {
            var _b = _a.payload, accessToken = _b.accessToken, accessTokenExpiresAt = _b.accessTokenExpiresAt, refreshToken = _b.refreshToken, refreshTokenExpiresAt = _b.refreshTokenExpiresAt;
            state.accessToken = accessToken;
            state.accessTokenExpiresAt = accessTokenExpiresAt;
            state.refreshToken = refreshToken;
            state.refreshTokenExpiresAt = refreshTokenExpiresAt;
        },
        logout: function (state) {
            state.accessToken = null;
            state.accessTokenExpiresAt = null;
            state.refreshToken = null;
            state.refreshTokenExpiresAt = null;
        },
    },
});
export var setAuthState = (_a = authStateSlice.actions, _a.setAuthState), logout = _a.logout;
export { authStateSlice };
