import styled from "@emotion/native";

const Container = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.sm,
  paddingVertical: theme.sizes.xxs,
}))

const Chip = styled.Text(({ theme }) => ({
  width: theme.sizes.lg,
  height: theme.sizes.lg,
  backgroundColor: theme.colors.primary,
  borderRadius: theme.sizes.md / 4,
  color: theme.colors.background0,
  textAlign: 'center',
  textAlignVertical: 'center',
}))

const Content = styled.View(({ theme }) => ({
  flex: 1,
  gap: theme.sizes.sm,
}))

const Collapsable = styled.TouchableOpacity(({ theme }) => ({
  flexDirection: 'column',
}))

export { Container, Chip, Content, Collapsable }
