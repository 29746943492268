import styled from '@emotion/native'

export interface SeparatorProps {
  size: number
}

export const Separator = styled.View<SeparatorProps>(({ size }) => ({
  height: size,
  width: size,
}))

export const Loading = styled.ActivityIndicator(({ theme }) => ({
  paddingVertical: theme.sizes.lg,
}))
