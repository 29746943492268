import React, { useState, useEffect } from 'react';

// Definición de interfaces
interface IconItem {
    id: number;
    link: string;
    iconClass: string;
    socialLink: string;
    svg?: JSX.Element;
}

interface FooterListItem {
    id: number;
    text?: string;
    image?: string;
}

interface InitData {
    image: string;
    text: string;
    linkText_1: string;
    linkText_2: string;
    linkText_3: string;
    iconList: IconItem[];
    footerList_1: FooterListItem[];
    footerList_2: FooterListItem[];
    footerList_3: FooterListItem[];
    footerList_4?: FooterListItem[]; // Campo opcional si no se utiliza
}

// Datos iniciales tipados
const initData: InitData = {
    image: "/landingPublic/img/logo.png",
    text: "Shining a Stellar Path in Software Development.",
    linkText_1: "Enlaces Útiles",
    linkText_2: "Soporte",
    linkText_3: "Descargas",
    iconList: [
        {
            id: 1,
            link: "linkedin",
            iconClass: "fab fa-linkedin-f",
            socialLink: "https://www.linkedin.com/company/quasar-codes/",
        },
        {
            id: 2,
            link: "instagram",
            iconClass: "fab fa-instagram",
            socialLink: "https://www.instagram.com/quasar_community",
        },
    ],
    footerList_1: [
        { id: 1, text: "Inicio" },
        { id: 2, text: "Nosotros" },
        { id: 3, text: "Servicios" },
        { id: 4, text: "Blog" },
        { id: 5, text: "Contacto" },
    ],
    footerList_2: [
        { id: 1, text: "FAQ" },
        { id: 2, text: "Política de Privacidad" },
        { id: 3, text: "Soporte" },
        { id: 4, text: "Términos y Condiciones" },
        { id: 5, text: "Contacto" },
    ],
    footerList_3: [
        { id: 1, image: "/landingPublic/img/google-play-black.png" },
        { id: 2, image: "/landingPublic/img/app-store-black.png" },
    ],
};

const FooterSection: React.FC = () => {
    const [data, setData] = useState<InitData | null>(null);
    const [iconList, setIconList] = useState<IconItem[]>([]);
    const [footerList_1, setFooterList_1] = useState<FooterListItem[]>([]);
    const [footerList_2, setFooterList_2] = useState<FooterListItem[]>([]);
    const [footerList_3, setFooterList_3] = useState<FooterListItem[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        try {
            setData(initData);
            setIconList(initData.iconList);
            setFooterList_1(initData.footerList_1);
            setFooterList_2(initData.footerList_2);
            setFooterList_3(initData.footerList_3);
        } catch (err) {
            setError("Ocurrió un error al cargar los datos.");
        }
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div className="height-emulator d-none d-lg-block" />
            <footer className="footer-area footer-fixed">
                {/* Footer Top */}
                <div className="footer-top ptb_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Logo */}
                                    <a className="navbar-brand" href="/#">
                                        <img className="logo" src={data?.image} alt="Logo" />
                                    </a>
                                    <p className="mt-2 mb-3">{data?.text}</p>
                                    {/* Social Icons */}
                                    <div className="social-icons d-flex">
                                        {iconList.map((item) => (
                                            <a
                                                key={`fi_${item.id}`}
                                                className={`social-icon ${item.link}`}
                                                href={item.socialLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) => {
                                                    console.log("Clicked on:", item.socialLink);
                                                }}
                                            >
                                                <img src={`/landingPublic/img/${item.link}.svg`} alt={item.link} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title mb-2">{data?.linkText_1}</h3>
                                    <ul>
                                        {footerList_1.map((item) => (
                                            <li key={`flo_${item.id}`} className="py-2">
                                                <a href="/#">{item.text}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title mb-2">{data?.linkText_2}</h3>
                                    <ul>
                                        {footerList_2.map((item) => (
                                            <li key={`flt_${item.id}`} className="py-2">
                                                <a href="/#">{item.text}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title mb-2">{data?.linkText_3}</h3>
                                    {/* Store Buttons */}
                                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                                        {footerList_3.map((item) => (
                                            <a key={`flth_${item.id}`} href="/#">
                                                <img src={item.image} alt={`Store ${item.id}`} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="copyright-left">
                                        © Copyrights 2024 Quasar Solutions All rights reserved.
                                    </div>
                                    {/* Copyright Right */}
                                    <div className="copyright-right">
                                        Made with ❤︎ By {" "}
                                        <a target="_blank" rel="noopener noreferrer" href="https://themeforest.net/user/theme_land">
                                            Quasar
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default FooterSection;
