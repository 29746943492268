import React, { useEffect } from 'react';
import '../../../../assets/landingAssets/css/style.css'; //Importar CSS de la landing
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Importación de componentes
import Header from '../../components/LandingHeader';
import HeroSection from '../../components/HeroSection';
import VideoPlayerSection from '../../components/VideoPlayerSection';
import ServiceSection from '../../components/ServiceSection';
import DiscoverSection from '../../components/DiscoverSection';
import FeatureSection from '../../components/FeatureSection';
import Work from '../../components/Work';
import ScreenshotSection from '../../components/ScreenshotSection';
import PricingSection from '../../components/PricingSection';
import FaqSection from '../../components/FaqSection';
import Download from '../../components/Download';
import FooterSection from '../../components/FooterSection';

const LandingPage: React.FC = () => {
    return (
        <div className="homepage-4">
            {/*====== Scroll To Top Area Start ======*/ }
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up" />
            </div>
            {/*====== Scroll To Top Area End ======*/ }
            <div className="main">
                <Header imageData="/landingPublic/img/logo-white.png" />
                <HeroSection />
                <VideoPlayerSection />
                <ServiceSection />
                <DiscoverSection />
                <FeatureSection />
                <Work />
                <ScreenshotSection />
                {/* <ReviewSection /> */}
                <PricingSection />
                <FaqSection />
                <Download />
                {/* <Subscribe /> */}
                {/* <ContactSection /> */}
                <FooterSection />
            </div>
        </div>
    );
};

export default LandingPage;
