import { baseApi } from '../base';
import { AUTH_RESET_PASSWORD_URL, AUTH_REGISTER_URL, AUTH_LOGIN_URL } from '../constants';
var headers = {
    'Client-ID': '8a193d0d-1b27-430a-9f18-e5b604499de5',
    'Client-Secret': '6f0ac6bf-278b-409d-b856-95a597a7e4cd'
};
export var authApi = baseApi.injectEndpoints({
    endpoints: function (builder) { return ({
        login: builder.mutation({
            query: function (body) { return ({
                url: AUTH_LOGIN_URL,
                method: 'POST',
                body: body,
                headers: headers,
            }); },
        }),
        register: builder.mutation({
            query: function (body) { return ({
                url: AUTH_REGISTER_URL,
                method: 'POST',
                body: body,
                headers: headers,
            }); },
        }),
        resetPassword: builder.mutation({
            query: function (body) { return ({
                url: "".concat(AUTH_RESET_PASSWORD_URL, "login"),
                method: 'POST',
                body: body,
                headers: headers,
            }); },
        }),
    }); },
});
export var useLoginMutation = authApi.useLoginMutation, useRegisterMutation = authApi.useRegisterMutation, useResetPasswordMutation = authApi.useResetPasswordMutation;
