var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    cart: {
        _id: '',
        profile_id: '',
        isActive: true,
        items: [],
        total: 0,
        savings: 0,
        currency: '',
        restaurant_id: '',
        total_with_discount: 0,
        total_original: 0,
    },
    currentItems: {},
    specialInstructions: {
        quantity: 1,
        message: '',
    },
};
var cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        addItem: function (_, __) { },
        updateItem: function (_, __) { },
        updateCartItemQuantity: function (_, __) { },
        updateCart: function (state, action) {
            state.cart = action.payload;
        },
        loadNestedItems: function (state, action) {
            state.currentItems = action.payload.reduce(function (acc, item) {
                if (item.section_id) {
                    if (!acc[item.section_id]) {
                        acc[item.section_id] = {};
                    }
                    acc[item.section_id][item._id] = item;
                }
                return acc;
            }, {});
        },
        addNestedItem: function (state, action) {
            console.log(action.payload);
            // TODO: respect MAX AND MIN
            if (!state.currentItems[action.payload.sectionId]) {
                state.currentItems[action.payload.sectionId] = {};
            }
            switch (action.payload.type) {
                case 'Simple':
                    // Clear previous
                    state.currentItems[action.payload.sectionId] = {};
                    // Append new value
                    state.currentItems[action.payload.sectionId][action.payload._id] = __assign(__assign({}, action.payload), { section_id: action.payload.sectionId });
                    return;
                // TODO: DO MULTIPLE SELECT AND QUANTITY LOGIC
                case 'Multiple':
                    if (Object.keys(state.currentItems[action.payload.sectionId]).length < action.payload.max_quantity) {
                        state.currentItems[action.payload.sectionId][action.payload._id] = __assign(__assign({}, action.payload), { section_id: action.payload.sectionId });
                    }
                    return;
                case 'Quantity':
                    var total = 0;
                    for (var key in state.currentItems[action.payload.sectionId]) {
                        total += state.currentItems[action.payload.sectionId][key].quantity;
                    }
                    if (total < action.payload.max_quantity) {
                        state.currentItems[action.payload.sectionId][action.payload._id] = __assign(__assign({}, action.payload), { section_id: action.payload.sectionId });
                    }
                    return;
                default:
                    return;
            }
        },
        deleteNestedItem: function (state, action) {
            delete state.currentItems[action.payload.sectionId][action.payload._id];
        },
        modifyNestedItem: function (state, action) {
            var _a, _b;
            // TODO: Add validation that no less than 0 and respect MAX AND MIN
            if ((_b = (_a = state.currentItems) === null || _a === void 0 ? void 0 : _a[action.payload.sectionId]) === null || _b === void 0 ? void 0 : _b[action.payload._id]) {
                if (action.payload.quantity >= 0) {
                    state.currentItems[action.payload.sectionId][action.payload._id] = __assign(__assign({}, action.payload), { section_id: action.payload.sectionId });
                }
            }
        },
        setSpecialMessage: function (state, action) {
            state.specialInstructions.message = action.payload;
        },
        setQuantity: function (state, action) {
            if (action.payload >= 1) {
                state.specialInstructions.quantity = action.payload;
            }
        },
        cleanQuantity: function (state) {
            state.specialInstructions = {
                quantity: 1,
                message: '',
            };
        },
        cleanNestedItems: function (state) {
            state.currentItems = {};
        },
    },
});
export var addItem = (_a = cartSlice.actions, _a.addItem), updateCart = _a.updateCart, addNestedItem = _a.addNestedItem, deleteNestedItem = _a.deleteNestedItem, modifyNestedItem = _a.modifyNestedItem, setSpecialMessage = _a.setSpecialMessage, cleanNestedItems = _a.cleanNestedItems, setQuantity = _a.setQuantity, cleanQuantity = _a.cleanQuantity, updateItem = _a.updateItem, loadNestedItems = _a.loadNestedItems, updateCartItemQuantity = _a.updateCartItemQuantity;
export { cartSlice };
