import React from 'react'
import { InputContainer, PageStepperContainer } from './styles'
import { ReactComponent as Envelope } from '../../../../../../assets/icons/envelope.svg'
import { ReactComponent as Lock } from '../../../../../../assets/icons/lock.svg'
import { ReactComponent as Person } from '../../../../../../assets/icons/person.svg'
import { Input, Text } from '@eassen/components'
import { useTheme } from '@emotion/react'

interface RegisterStep1Props {
  formik: any
}
const RegisterStep1: React.FC<RegisterStep1Props> = ({ formik }) => {
  const { values, errors, handleChange, handleBlur } = formik
  const theme = useTheme()

  return (
    <PageStepperContainer>
      <InputContainer>
        <Input
          icon={<Person width={theme.scale(20)} height={theme.scale(20)} />}
          inputProps={{
            value: values.firstName,
            onChangeText: handleChange('firstName'),
            onBlur: handleBlur('firstName'),
            placeholder: 'First Name',
          }}
        />
        {errors.firstName && (
          <Text type="small" color="error">
            {errors.firstName}
          </Text>
        )}
      </InputContainer>
      <InputContainer>
        <Input
          icon={<Person width={theme.scale(20)} height={theme.scale(20)} />}
          inputProps={{
            value: values.lastName,
            onChangeText: handleChange('lastName'),
            onBlur: handleBlur('lastName'),
            placeholder: 'Last Name',
          }}
        />
        {errors.lastName && (
          <Text type="small" color="error">
            {errors.lastName}
          </Text>
        )}
      </InputContainer>
      <InputContainer>
        <Input
          icon={<Envelope width={theme.scale(20)} height={theme.scale(20)} />}
          inputProps={{
            value: values.email,
            onChangeText: handleChange('email'),
            onBlur: handleBlur('email'),
            placeholder: 'email@address.com',
          }}
        />
        {errors.email && (
          <Text type="small" color="error">
            {errors.email}
          </Text>
        )}
      </InputContainer>
      <InputContainer>
        <Input
          icon={<Envelope width={theme.scale(20)} height={theme.scale(20)} />}
          inputProps={{
            value: values.confirmEmail,
            onChangeText: handleChange('confirmEmail'),
            onBlur: handleBlur('confirmEmail'),
            placeholder: 'email@address.com',
          }}
        />
        {errors.confirmEmail && (
          <Text type="small" color="error">
            {errors.confirmEmail}
          </Text>
        )}
      </InputContainer>
      <InputContainer>
        <Input
          icon={<Lock width={theme.scale(20)} height={theme.scale(20)} />}
          inputProps={{
            secureTextEntry: true,
            value: values.password,
            onChangeText: handleChange('password'),
            onBlur: handleBlur('password'),
            placeholder: '********',
          }}
        />
        {errors.password && (
          <Text type="small" color="error">
            {errors.password}
          </Text>
        )}
      </InputContainer>
    </PageStepperContainer>
  )
}

export default RegisterStep1
