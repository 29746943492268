import React, { useState, useEffect } from 'react';

// Definición de la estructura de los datos
interface HeroData {
    heading: string;
    headingTwo: string;
    content: string;
    headingSlug: string;
    playImg: string;
    appleImg: string;
    heroThumb: string;
}

// Datos iniciales tipados
const initData: HeroData = {
    heading: "Quasar Community",
    headingTwo: "Aplicación de Catálogo de Cursos",
    content: "Descubre nuestra plataforma de cursos al estilo streaming. Explora una variedad de temas, recibe recomendaciones personalizadas y aprende a tu propio ritmo, desde cualquier lugar y dispositivo.",
    headingSlug: "* Disponible en iPhone, todos los dispositivos Android y Web",
    playImg: "/landingPublic/img/google-play.png",
    appleImg: "/landingPublic/img/app-store.png",
    heroThumb: "/landingPublic/img/welcome_mockup_2.png",
};

const HeroSection: React.FC = () => {
    // Estado tipado
    const [data, setData] = useState<HeroData | null>(null);

    useEffect(() => {
        setData(initData);
    }, []);

    return (
        <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 order-2 order-md-1">
                        {/* Welcome Thumb */}
                        <div className="welcome-thumb mx-auto">
                            {data && <img src={data.heroThumb} alt="" />}
                        </div>
                    </div>
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-md-6 order-1 order-md-2">
                        <div className="welcome-intro px-0 px-lg-4">
                            <h1 className="text-white font-italic mb-2 mb-md-3">{data?.heading}</h1>
                            <h3 className="text-white text-capitalize">{data?.headingTwo}</h3>
                            <p className="text-white mt-3 mb-4">{data?.content}</p>
                            {/* Store Buttons */}
                            <div className="button-group store-buttons d-flex">
                                <a href="/download-page">
                                    {data && <img src={data.playImg} alt="" />}
                                </a>
                                <a href="/download-page">
                                    {data && <img src={data.appleImg} alt="" />}
                                </a>
                            </div>
                            <span className="d-inline-block text-white fw-3 font-italic mt-3">{data?.headingSlug}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
