import styled from '@emotion/native'
import TextInput from '../../atoms/TextInput'
import TouchableText from '../../atoms/TouchableText'

interface InputWrapperViewProps {
  keyboardActive?: boolean
}

const SearchInputContainer = styled.View(({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  flexDirection: 'row',
  paddingRight: theme.sizes.sm,
}))

const InputContainer = styled.View(() => ({
  width: '100%',
}))

const InputWrapper = styled(TextInput)<InputWrapperViewProps>(({ theme, keyboardActive }) => ({
  borderRadius: theme.sizes.xxs,
  borderWidth: 1,
  borderColor: '#EEEEEE',
  paddingVertical: theme.sizes.xxxs,
  marginRight: keyboardActive ? theme.sizes.xxxs : undefined,
}))

const CancelTouchableText = styled(TouchableText)(({ theme }) => ({
  paddingLeft: theme.sizes.sm,
}))

export { SearchInputContainer, InputWrapper, CancelTouchableText, InputContainer }
