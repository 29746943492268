import React, { useEffect, useState } from 'react';

// Definición de interfaces
interface FaqItem {
    id: number;
    title: string;
    content: string;
}

interface FaqData {
    heading: string;
    headingTwo: string;
    headingText: string;
    headingTexttwo: string;
    faqText: string;
    faqTextLink: string;
    faqData: FaqItem[];
    faqDataTwo: FaqItem[];
}

const FaqSection: React.FC = () => {
    const [data, setData] = useState<FaqData | null>(null);
    const [faqData, setFaqData] = useState<FaqItem[]>([]);
    const [faqDataTwo, setFaqDataTwo] = useState<FaqItem[]>([]);

    useEffect(() => {
        // Simulación de la respuesta de la API
        const res: FaqData = {
            heading: "Preguntas frecuentes",
            headingTwo: "Have questions? Look here",
            headingText: "Aquí podrás encontrar información clave sobre el acceso a los cursos, certificaciones, y los beneficios que obtienes al usar esta plataforma.",
            headingTexttwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
            faqText: "No encontraste la respuesta que necesitabas?",
            faqTextLink: "Dinos que necesitas",
            faqData: [
                {
                    id: 1,
                    title: "¿Qué tipos de cursos están disponibles en la plataforma?",
                    content: "Ofrecemos una variedad de cursos centrados en tecnologías modernas como React Native, JavaScript, y herramientas para desarrolladores de apps móviles. Aquí puedes aprender sobre animaciones de componentes, configuraciones de CI/CD, despliegues en tiendas de apps, creación de hooks personalizados y más."
                },
                {
                    id: 2,
                    title: "¿Quién puede acceder a los cursos?",
                    content: "Cualquier persona interesada en mejorar sus habilidades de programación puede acceder a nuestros cursos. Solo necesitas registrarte en la plataforma, pagar la suscripción que más se adecúe a tus necesidades y comenzar a explorar los cursos disponibles."
                },
                {
                    id: 3,
                    title: "¿Ofrecen algún tipo de certificación al completar los cursos?",
                    content: "Al finalizar cada curso, otorgamos un diploma digital avalado por Quasar Community que podrás añadir a tu perfil profesional o incluir en tu CV. Estos diplomas están diseñados para ayudarte a destacar en el mercado laboral y demostrar tu experiencia en temas específicos."
                }
            ],
            faqDataTwo: [
                {
                    id: 1,
                    title: "¿Cómo puedo encontrar cursos adecuados a mi nivel de experiencia?",
                    content: "Nuestra plataforma clasifica los cursos por niveles: principiante, intermedio y avanzado. Puedes filtrar los cursos según tu nivel de experiencia o interés, permitiéndote aprender de forma gradual o especializarte en temas avanzados."
                },
                {
                    id: 2,
                    title: "¿Es posible acceder a los cursos desde cualquier dispositivo?",
                    content: "Sí, nuestra aplicación está disponible en dispositivos móviles, tabletas y computadoras. También puedes disfrutar de los cursos en plataformas de TV compatibles, para que puedas aprender donde y cuando prefieras."
                },
                {
                    id: 3,
                    title: "¿Cómo puede ayudarme esta plataforma a conseguir empleo en los EE. UU.?",
                    content: "Además de proporcionarte contenido técnico de alto nivel, nuestros cursos te ayudarán a mejorar tus habilidades en las áreas más demandadas por empresas en los EE. UU. Con el tiempo, podrás crear un perfil profesional completo, incluyendo certificaciones y experiencias que aumentarán tus oportunidades de trabajo en el mercado internacional."
                }
            ]
        };

        setData(res);
        setFaqData(res.faqData || []);
        setFaqDataTwo(res.faqDataTwo || []);
    }, []);

    return (
        <section className="section faq-area style-two ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{data?.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{data?.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{data?.headingTexttwo}</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12">
                        {/* FAQ Content */}
                        <div className="faq-content">
                            {/* sApp Accordion */}
                            <div className="accordion" id="sApp-accordion">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {faqData.map((item) => (
                                            <div key={`fo_${item.id}`} className="card border-0">
                                                {/* Card Header */}
                                                <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                </div>
                                                {/* Card Body */}
                                                <div className="card-body px-0 py-3">
                                                    {item.content}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {faqDataTwo.map((item) => (
                                            <div key={`ft_${item.id}`} className="card border-0">
                                                {/* Card Header */}
                                                <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                </div>
                                                {/* Card Body */}
                                                <div className="card-body px-0 py-3">
                                                    {item.content}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <p className="text-body text-center pt-4 fw-5">
                                        {data?.faqText} <a href="mailto: community@quasarcodes.com">{data?.faqTextLink}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqSection;
