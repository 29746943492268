import styled from '@emotion/native'
import Text from '../../atoms/Text'

export const Container = styled.TouchableOpacity(({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  gap: theme.sizes.xxs,
  alignItems: 'center',
  paddingVertical: theme.sizes.md,
}))

export const DescriptionContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
  flex: 1,
}))

export const Image = styled.Image({
  width: '25%',
  aspectRatio: 1,
  borderRadius: 8,
})

export const PriceContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.sizes.xxs,
}))

export const DiscountPill = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.success,
  paddingHorizontal: theme.sizes.xs,
  paddingVertical: theme.sizes.xxxs,
  borderRadius: 50,
}))

export const PriceText = styled(Text)<{ isDiscount?: boolean }>(({ isDiscount }) => ({
  textDecorationLine: isDiscount ? 'line-through' : undefined,
}))

export const TitleWrapper = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxs,
  alignItems: 'center',
}))

export const Quantity = styled.View(({ theme }) => ({
  paddingHorizontal: theme.scale(10),
  paddingVertical: theme.scale(4),
  borderRadius: 8,
  backgroundColor: theme.colors.primary,
}))
