var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { baseApi } from '@eassen/apis';
import { addItem, cleanNestedItems, cleanQuantity, updateCart } from '../slices';
import { startAppListening } from '../store/listenerMiddleware';
export var AddCartItemListener = function () {
    return startAppListening({
        actionCreator: addItem,
        effect: function (action, listenerApi) { return __awaiter(void 0, void 0, void 0, function () {
            var state, elements_1, result, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        console.debug("Trying to add to shopping cart id: ".concat(action.payload._id));
                        state = listenerApi.getState().cart;
                        elements_1 = [];
                        Object.values(state.currentItems).forEach(function (nestedObject) {
                            Object.values(nestedObject).forEach(function (item) {
                                elements_1.push({
                                    quantity: item.quantity,
                                    _id: item._id,
                                    section_id: item.section_id,
                                });
                            });
                        });
                        console.log(JSON.stringify(elements_1), 'ELEMENTS');
                        return [4 /*yield*/, listenerApi.dispatch(baseApi.endpoints.putCartItem.initiate({
                                _id: action.payload._id,
                                section_id: (_a = action.payload.section_id) !== null && _a !== void 0 ? _a : '',
                                restaurant_id: '66fc00c384151ee06e9752a8', // TODO: SHOULD NOT HARDCODE
                                quantity: state.specialInstructions.quantity,
                                items: elements_1,
                                message: state.specialInstructions.message,
                            }))];
                    case 1:
                        result = _b.sent();
                        if ('data' in result) {
                            // Handle success
                            console.log(JSON.stringify(result.data), 'RESULTTTTTTT');
                            listenerApi.dispatch(updateCart(result.data));
                            // Clean the items used in dish details
                            listenerApi.dispatch(cleanNestedItems());
                            // Clean special instructions
                            listenerApi.dispatch(cleanQuantity());
                        }
                        else if ('error' in result) {
                            // TODO: HANDLE GLOBAL ERROR
                            console.debug('API error:', result.error);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        // TODO: HANDLE GLOBAL ERROR
                        console.debug("Error: ".concat(e_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); },
    });
};
