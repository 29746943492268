import React, { useState, useEffect } from 'react';

// Definición de la estructura de los datos
interface DiscoverText {
    id: string;
    text: string;
}

interface DiscoverData {
    thumb: string;
    heading: string;
    text: DiscoverText[];
}

// Datos iniciales tipados
const initData: DiscoverData = {
    thumb: "/landingPublic/img/discover_thumb_1.png",
    heading: "Contenido para todos los niveles",
    text: [
        {
            id: "1",
            text: "Nuestra plataforma está diseñada para desarrolladores en cualquier etapa de su carrera. Ya sea que estés empezando o quieras llevar tus habilidades al siguiente nivel, tenemos contenido para ti."
        },
        {
            id: "2",
            text: "Principiantes: Aprende los conceptos esenciales del desarrollo y adquiere las habilidades necesarias para comenzar a ganar dinero rápidamente."
        },
        {
            id: "3",
            text: "Intermedios: Mejora tus conocimientos con cursos más especializados que te prepararán para proyectos más desafiantes y mejor remunerados."
        },
        {
            id: "4",
            text: "Avanzados: Domina técnicas avanzadas que te ayudarán a perfeccionar tus habilidades, abrir nuevas oportunidades y alcanzar ingresos de +$5k al mes."
        }
    ]
};

const DiscoverSection: React.FC = () => {
    // Estado tipado
    const [data, setData] = useState<DiscoverData | null>(null);

    useEffect(() => {
        setData(initData);
    }, []);

    return (
        <section className="section discover-area bg-gray overflow-hidden ptb_100">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-8 col-lg-6 order-2 order-lg-1">
                        {/* Discover Thumb */}
                        <div className="service-thumb discover-thumb mx-auto text-center pt-5 pt-lg-0">
                            {data && <img src={data.thumb} alt="" />}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2">
                        {/* Discover Text */}
                        <div className="discover-text pt-4 pt-lg-0 px-0 px-lg-4">
                            <h2 className="pb-4">{data?.heading}</h2>
                            {data?.text.map((item) => (
                                <p key={item.id} className="mb-3">{item.text}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DiscoverSection;
