import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    id: null,
    name: null,
    email: null,
    phoneNumber: null,
    roleId: null,
    profileId: null,
    points: null,
};
var profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        setUser: function (state, _a) {
            var payload = _a.payload;
            state.id = payload.id;
            state.name = payload.name;
            state.email = payload.email;
            state.phoneNumber = payload.phoneNumber;
            state.profileId = payload.profileId;
            state.roleId = payload.roleId;
            state.points = payload.points;
        },
    },
});
export var setUser = profileSlice.actions.setUser;
export { profileSlice };
