import { baseApi } from "../base";
var PERSONALIZATION_URL = 'https://voqqe8iu77.execute-api.us-east-1.amazonaws.com';
export var personalizationApi = baseApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getPoints: builder.mutation({
            query: function (_) { return ({
                url: "".concat(PERSONALIZATION_URL, "/points"),
                method: 'GET',
            }); }
        }),
        getPointsHistory: builder.mutation({
            query: function (_) { return ({
                url: "".concat(PERSONALIZATION_URL, "/points/history"),
                method: 'GET',
            }); }
        })
    }); }
});
export var useGetPointsMutation = personalizationApi.useGetPointsMutation, useGetPointsHistoryMutation = personalizationApi.useGetPointsHistoryMutation;
