import styled from '@emotion/native'

export const Container = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.background0,
  elevation: 5,
  borderRadius: theme.scale(8),
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 4 },
  shadowOpacity: 0.3,
  shadowRadius: 5,
}))
export const DiscountPill = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.success,
  paddingHorizontal: theme.sizes.xs,
  paddingVertical: theme.sizes.xxxs,
  borderRadius: 50,
}))
export const Row = styled.View({ flexDirection: 'row', flex: 1 })
export const Column = styled.View({ flexDirection: 'column' })
export const PriceColumn = styled.View(() => ({ flexDirection: 'column', flex: 1 }))

export const ImageWrapper = styled.View(({ theme }) => ({ aspectRatio: 1, borderRadius: theme.scale(8) }))
export const DescWrapper = styled.View(({ theme }) => ({ flex: 0.6, padding: theme.scale(8) }))
export const PriceWrapper = styled.View(({ theme }) => ({
  flex: 0.2,
  alignItems: 'flex-end',
  padding: theme.sizes.xxs,
}))
export const PriceContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxxs,
}))
export const Separator = styled.View(({ theme }) => ({
  height: theme.sizes.xs,
  width: theme.sizes.xs,
}))
