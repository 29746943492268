import React, { useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import { FaBars, FaTimes } from "react-icons/fa";

interface HeaderProps {
  imageData: string;
}

const Header: React.FC<HeaderProps> = ({ imageData }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsSticky(window.scrollY > 50);
    const handleResize = () => setIsMobile(window.innerWidth < 991);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuStateChange = (state: { isOpen: boolean }) => {
    setMenuOpen(state.isOpen);
  };

  const navLinks = (
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link scroll" href="/">Inicio</a>
      </li>
      <li className="nav-item">
        <a className="nav-link scroll" href="#features">Features</a>
      </li>
      <li className="nav-item">
        <a className="nav-link scroll" href="#screenshots">Capturas</a>
      </li>
      <li className="nav-item">
        <a className="nav-link scroll" href="#pricing">Precios</a>
      </li>
      <li className="nav-item">
        <a className="nav-link scroll" href="/auth/login">Ingresar</a>
      </li>
    </ul>
  );

  return (
    <header
      className={`navbar navbar-sticky navbar-expand-lg navbar-dark ${
        isSticky ? "navbar-sticky-on" : ""
      }`}
    >
      <div className="container position-relative">
        <a className="navbar-brand" href="/">
          <img
            className="navbar-brand-regular"
            src={imageData}
            alt="brand-logo"
          />
          <img
            className="navbar-brand-sticky"
            src="/landingPublic/img/logo.png"
            alt="sticky brand-logo"
          />
        </a>
        {isMobile ? (
          <Menu
            right
            isOpen={menuOpen}
            onStateChange={handleMenuStateChange}
            customBurgerIcon={
              <FaBars color={isSticky ? "#373a47" : "#fff"} size={24} />
            }
            customCrossIcon={<FaTimes color="#373a47" size={24} />}
          >
            {navLinks}
          </Menu>
        ) : (
          <div className="navbar-inner">
            <nav>{navLinks}</nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
