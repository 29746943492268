import React, { Component } from "react";
import Slider from "react-slick";

// Definición de interfaces
interface InitData {
  preHeading: string;
  preHeadingspan: string;
  heading: string;
  headingText: string;
}

interface ScreenshotItem {
  image: string;
}

interface ScreenshotSectionState {
  initData: InitData;
  data: ScreenshotItem[];
}

// Datos iniciales tipados
const initData: InitData = {
  preHeading: "Awesome",
  preHeadingspan: "Interface",
  heading: "Intuitiva y fácil de usar",
  headingText:
    "Nuestra app ofrece mucho más que simples videos. Es una herramienta integral para mejorar tu carrera en desarrollo.",
};

const data: ScreenshotItem[] = [
  { image: "/landingPublic/img/screenshot_1.png" },
  { image: "/landingPublic/img/screenshot_2.png" },
  { image: "/landingPublic/img/screenshot_3.png" },
  { image: "/landingPublic/img/screenshot_4.png" },
  { image: "/landingPublic/img/screenshot_5.png" },
];

class ScreenshotSection extends Component<{}, ScreenshotSectionState> {
  state: ScreenshotSectionState = {
    initData: {} as InitData,
    data: [],
  };

  componentDidMount() {
    this.setState({
      initData: initData,
      data: data,
    });
  }

  render() {
    // Configuración del slider
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true, // Centrar la imagen
            centerPadding: "0px", // Sin padding para mantenerla bien centrada
          },
        },
      ],
    };          

    return (
      <section id="screenshots" className="section screenshots-area ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">
                  {this.state.initData.heading}
                </h2>
                <p className="d-none d-sm-block mt-4">
                  {this.state.initData.headingText}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* Slider de Screenshots */}
              <Slider {...settings}>
                {this.state.data.map((item, idx) => (
                  <div key={`so_${idx}`} className="single-screenshot">
                    <img
                      src={item.image}
                      alt={`Screenshot ${idx + 1}`}
                      style={{ width: "100%", height: "auto", borderRadius: "10px" }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ScreenshotSection;
