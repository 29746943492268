import React, { useState, useEffect } from 'react';

// Definición de interfaces
interface WorkItem {
    image: string;
    title: string;
    content: string;
}

interface WorkData {
    heading: string;
    headingText: string;
    workData: WorkItem[];
}

// Datos iniciales tipados
const initData: WorkData = {
    heading: "Una experiencia de aprendizaje personalizada",
    headingText: "La interfaz de nuestra plataforma está inspirada en la experiencia de un servicio de streaming.",
    workData: [
        {
            image: "/landingPublic/img/work_thumb_1.png",
            title: "Continuar justo donde te quedaste",
            content: "No pierdas el progreso, tu curso estará siempre disponible desde donde lo dejaste."
        },
        {
            image: "/landingPublic/img/work_thumb_2.png",
            title: "Recomendaciones personalizadas",
            content: "Basadas en tu historial de cursos y preferencias, recibirás sugerencias que se alineen con tus intereses."
        },
        {
            image: "/landingPublic/img/work_thumb_3.png",
            title: "Soporte multiplataforma",
            content: "Accede a tus cursos desde cualquier dispositivo: computadora, tableta o teléfono."
        }
    ]
};

const Work: React.FC = () => {
    // Estado tipado
    const [data, setData] = useState<WorkData | null>(null);

    useEffect(() => {
        setData(initData);
    }, []);

    return (
        <section className="section work-area bg-overlay overflow-hidden ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Work Content */}
                        <div className="work-content text-center">
                            <h2 className="text-white">{data?.heading}</h2>
                            <p className="text-white my-3 mt-sm-4 mb-sm-5">{data?.headingText}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {data?.workData.map((item, idx) => (
                        <div key={`w_${idx}`} className="col-12 col-md-4">
                            {/* Single Work */}
                            <div className="single-work text-center p-3">
                                {/* Work Icon */}
                                <div className="work-icon">
                                    <img className="avatar-md" src={item.image} alt={item.title} />
                                </div>
                                <h3 className="text-white py-3 px-3">{item.title}</h3>
                                <p className="text-white">{item.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Work;
