var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AUTH_REGISTER_URL } from './constants';
var baseQuery = fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: function (headers, api) {
        var accessToken = api.getState().authState.accessToken;
        if (accessToken) {
            headers.set('Authorization', "Bearer ".concat(accessToken));
        }
        return headers;
    },
});
// TODO: ADD BACKLIST
var baseQueryWithReauth = function (args, api, extraOptions) { return __awaiter(void 0, void 0, void 0, function () {
    var result, isNotRegisterUserEndpoint, refreshToken;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log(JSON.stringify(args), JSON.stringify(api), JSON.stringify(extraOptions));
                return [4 /*yield*/, baseQuery(args, api, extraOptions)];
            case 1:
                result = _b.sent();
                isNotRegisterUserEndpoint = args.url !== AUTH_REGISTER_URL;
                if (((_a = result.error) === null || _a === void 0 ? void 0 : _a.status) === 401 && isNotRegisterUserEndpoint) {
                    refreshToken = api.getState().authState.refreshToken;
                    // let refreshResult = await baseQuery(
                    //   {
                    //     url: AUTH_REFRESH_TOKEN_URL,
                    //     method: 'POST',
                    //     body: { token: refreshToken },
                    //   },
                    //   api,
                    //   extraOptions,
                    // )
                    // if (refreshResult.error?.status === 401) {
                    //   const { appNavigationState } = (api.getState() as any).appState
                    //   const isAnonymous = appNavigationState === 'ANONYMOUS';
                    //   if (isAnonymous) {
                    //     refreshResult = await baseQuery(
                    //       {
                    //         url: AUTH_LOGIN_GUEST_URL,
                    //         method: 'GET',
                    //       },
                    //       api,
                    //       extraOptions,
                    //     )
                    //   }
                    // }
                    // //TODO: handle Too many requests response
                    // if (refreshResult.error?.status === 429) {
                    //   console.error(result.error?.data)
                    // }
                    // if (refreshResult.data) {
                    //   const { access_token, access_token_expiry, refresh_token, refresh_token_expiry } = refreshResult.data as any
                    //   api.dispatch({
                    //     type: 'auth/setAuthState',
                    //     payload: {
                    //       accessToken: access_token,
                    //       accessTokenExpiresAt: access_token_expiry,
                    //       refreshToken: refresh_token,
                    //       refreshTokenExpiresAt: refresh_token_expiry,
                    //     },
                    //   })
                    //   if ((args as FetchArgs).url === AUTH_AUTHENTICATE_URL) {
                    //     (args as FetchArgs).body.token = access_token;
                    //   }
                    //   result = await baseQuery(args, api, extraOptions)
                    // } else {
                    //   api.dispatch({ type: 'auth/logout' })
                    // }
                }
                return [2 /*return*/, result];
        }
    });
}); };
export var baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseQueryWithReauth,
    endpoints: function () { return ({}); },
    refetchOnReconnect: true,
});
