import styled from '@emotion/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { ScreenType } from '@eassen/themes'
import AppBackground from '../../atoms/AppBackground'

export const MainWrapper = styled(AppBackground)<{screenType:ScreenType}>(({ theme, screenType }) => ({
  padding: theme.sizes.md,
  flex: 1,
  maxWidth: 450,
  height: screenType === 'Small' ? '100%' : 'auto',
  borderWidth: screenType === 'Small' ? 0 : 3,
  borderColor: theme.colors.background1,
  backgroundColor: screenType === 'Small' ? theme.colors.background0 : theme.colors.background2,
  borderRadius: theme.sizes.md,
}))

export const Container = styled(KeyboardAwareScrollView)<{screenType:ScreenType}>(({screenType, theme})=>({
  paddingBottom: screenType === 'Small' ? 0 : theme.sizes.xxl * 2,
}))

export const Header = styled.View(({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.sizes.xl,
}))

export const InputContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
}))

export const TextContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  gap: theme.sizes.md,
}))

export const FormsContainer = styled.View(({ theme }) => ({
  flex: 1,
  gap: theme.sizes.md,
}))

export const ActionsContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.sizes.lg,
}))

export const RegisterText = styled.View(({ theme }) => ({
  gap: theme.sizes.xxs,
  flexDirection: 'row',
}))

export const ButtonContainer = styled.View(({ theme }) => ({
  justifyContent: 'flex-end',
  flex: 1,
  paddingBottom: 1,
}))

export const IconButton = styled.TouchableOpacity(({ theme }) => ({
  paddingRight: theme.sizes.sm,
  paddingVertical: theme.sizes.sm,
  position: 'absolute',
  left: theme.sizes.md,
  zIndex: 3,
}))
