import React from 'react'
import { Container, SafeContainer } from './styles'
import { StyleProp, ViewStyle } from 'react-native'
import { Edges } from 'react-native-safe-area-context'

export interface AppBackgroundProps {
  isSafe?: boolean
  children?: React.ReactNode
  edges?: Edges
  style?: StyleProp<ViewStyle>
}
const AppBackground: React.FC<AppBackgroundProps> = (props) => {
  if (props.isSafe) {
    return (
      <SafeContainer edges={props.edges}>
        <Container style={props.style}>{props.children}</Container>
      </SafeContainer>
    )
  }

  return <Container style={props.style}>{props.children}</Container>
}

export default AppBackground
