var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { baseApi } from '../base';
var baseUrl = 'https://m7a2wun8p6.execute-api.us-east-1.amazonaws.com/';
export var cartApi = baseApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getCart: builder.mutation({
            query: function (_a) {
                var restaurant_id = _a.restaurant_id;
                return ({
                    url: "".concat(baseUrl, "cart/").concat(restaurant_id),
                    method: 'GET',
                });
            },
        }),
        putCartItem: builder.mutation({
            query: function (_a) {
                var restaurant_id = _a.restaurant_id, body = __rest(_a, ["restaurant_id"]);
                return ({
                    url: "".concat(baseUrl, "cart/").concat(restaurant_id),
                    method: 'PUT',
                    body: body,
                });
            },
        }),
        updateCartItem: builder.mutation({
            query: function (_a) {
                var restaurant_id = _a.restaurant_id, item_identifier = _a.item_identifier, body = __rest(_a, ["restaurant_id", "item_identifier"]);
                return ({
                    url: "".concat(baseUrl, "cart/update/").concat(restaurant_id, "?item_identifier=").concat(item_identifier),
                    method: 'PUT',
                    body: body,
                });
            },
        }),
        updateCartItemQuantity: builder.mutation({
            query: function (_a) {
                var restaurant_id = _a.restaurant_id, item_identifier = _a.item_identifier, quantity = _a.quantity;
                return ({
                    url: "".concat(baseUrl, "cart/update/quantity/").concat(restaurant_id, "?item_identifier=").concat(item_identifier, "&quantity=").concat(quantity),
                    method: 'PUT',
                });
            },
        }),
        deleteCartItem: builder.mutation({
            query: function (_a) {
                var restaurant_id = _a.restaurant_id, item_identifier = _a.item_identifier;
                return ({
                    url: "".concat(baseUrl, "cart/").concat(restaurant_id, "?item_identifier=").concat(item_identifier),
                    method: 'DELETE',
                });
            },
        }),
        getAllCarts: builder.mutation({
            query: function (_) { return ({
                url: "".concat(baseUrl, "cart"),
                method: 'GET',
            }); },
        }),
    }); },
});
export var useGetCartMutation = cartApi.useGetCartMutation, usePutCartItemMutation = cartApi.usePutCartItemMutation, useGetAllCartsMutation = cartApi.useGetAllCartsMutation, useDeleteCartItemMutation = cartApi.useDeleteCartItemMutation;
