var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
var initialState = {
    loading: false,
    imageUrl: null,
    error: null,
};
var IMAGE_SERVER = 'https://w85rm56xy8.execute-api.us-east-1.amazonaws.com/images';
export var uploadImage = createAsyncThunk('image/uploadImage', function (imageFile_1, _a) { return __awaiter(void 0, [imageFile_1, _a], void 0, function (imageFile, _b) {
    var formData, response, data, imageUrl, error_1;
    var rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                formData = new FormData();
                formData.append('file', imageFile);
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 5]);
                return [4 /*yield*/, fetch("".concat(IMAGE_SERVER), {
                        method: 'POST',
                        body: formData,
                    })];
            case 2:
                response = _c.sent();
                if (!response.ok)
                    throw new Error('Network response was not ok');
                return [4 /*yield*/, response.json()];
            case 3:
                data = _c.sent();
                imageUrl = "".concat(IMAGE_SERVER, "/").concat(data.Key);
                return [2 /*return*/, imageUrl];
            case 4:
                error_1 = _c.sent();
                console.error(error_1);
                if (error_1 instanceof Error) {
                    return [2 /*return*/, rejectWithValue(error_1.message)];
                }
                return [2 /*return*/, rejectWithValue('An unexpected error occurred')];
            case 5: return [2 /*return*/];
        }
    });
}); });
var imageSlice = createSlice({
    name: 'image',
    initialState: initialState,
    reducers: {
        setImageUrl: function (state, _a) {
            var payload = _a.payload;
            state.imageUrl = payload;
        },
        setLoadingImage: function (state, _a) {
            var payload = _a.payload;
            state.loading = payload;
        },
        setErrorImage: function (state, _a) {
            var payload = _a.payload;
            state.error = payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(uploadImage.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(uploadImage.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
            state.imageUrl = payload;
            state.error = null;
        })
            .addCase(uploadImage.rejected, function (state, action) {
            state.loading = false;
            state.error = action.error || 'Failed to upload image';
        });
    },
});
export var setImageUrl = (_a = imageSlice.actions, _a.setImageUrl), setLoadingImage = _a.setLoadingImage, setErrorImage = _a.setErrorImage;
export { imageSlice };
