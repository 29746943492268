import styled from '@emotion/native'
import { Platform, View, ViewStyle } from 'react-native'
import { Colors, TextSizes } from '@eassen/themes'

export interface InputProps extends ViewStyle {}

const StyledInput = styled(View)<InputProps>(({ theme, paddingVertical }) => ({
  borderRadius: theme.sizes.xs,
  backgroundColor: theme.colors.background1,
  position: 'relative',
  paddingVertical: paddingVertical ?? (Platform.OS == 'ios' ? theme.sizes.md : theme.sizes.xs),
}))

const StyledLabelView = styled(View)<InputProps>(({ theme }) => ({
  position: 'absolute',
  top: -(theme.fontSizes.caption.fontSize / 1.5),
  left: theme.sizes.xs,
  width: 'auto',
  paddingHorizontal: theme.sizes.xxxs,
  alignSelf: 'flex-start',
  backgroundColor: theme.colors.background0 as keyof Colors,
  lineHeight: theme.fontSizes.caption.lineHeight !== 0 ? theme.fontSizes.caption.lineHeight : undefined,
}))

const StyledInputContainer = styled(View)<InputProps>(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  paddingHorizontal: theme.sizes.xs,
}))

const StyledInputIconContainer = styled(View)<InputProps>(({ theme }) => ({
  paddingRight: theme.sizes.xs,
  paddingLeft: 0,
}))

const StyledTextInput = styled.TextInput<InputProps & { typography: keyof TextSizes }>(({ theme, typography }) => {
  return {
    flex: 1,
    width: '100%',
    // minHeight: theme.fontSizes[typography].fontSize * 1.5,
    color: theme.colors[theme.fontSizes[typography].color],
    fontWeight: Platform.OS === 'ios' ? '400' : undefined,
    lineHeight: theme.fontSizes[typography].lineHeight,
    letterSpacing: theme.fontSizes[typography].letterSpacing,
    fontSize: theme.fontSizes[typography].fontSize,
    fontFamily: theme.fontSizes[typography].fontFamily,
    textAlign: 'left',
    alignSelf: 'center',
  }
})

export { StyledInput, StyledLabelView, StyledInputContainer, StyledInputIconContainer, StyledTextInput }
