import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import styled from '@emotion/native'

export const AddCardContainer = styled.View(({ theme }) => ({
  backgroundColor: 'white',
  paddingTop: theme.scale(25),
  gap: theme.sizes.lg,
}))

export const CloseTouchable = styled.TouchableOpacity({
  position: 'absolute',
  right: 0,
})

export const Container = styled(KeyboardAwareScrollView)(() => ({
}))

export const FormContainer = styled.View(({ theme }) => ({
  paddingTop: theme.sizes.xs,
  flex: 1,
  gap: theme.scale(20),
}))

export const InputContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
  width: '100%',
}))

export const DobleInputRowContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
  width: '48%',
}))


export const Header = styled.View(() => ({
  width: '100%',
}))

export const DobleInputContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}))