import styled from '@emotion/native'
import Text from '../Text'
import Button from '../../molecules/Button'

export const Container = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.background0,
  elevation: 5,
  borderRadius: theme.scale(8),
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 4 },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  padding: theme.sizes.xxs,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const AddOnTitle = styled(Text)(({ theme }) => ({
  fontSize: theme.scale(14),
}))

export const Currency = styled(Text)(({ theme }) => ({
  fontSize: theme.scale(12),
  paddingHorizontal: theme.sizes.md,
}))

export const RightWarpper = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
}))

export const CheckBoxWrapper = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.background1,
  margin: -theme.sizes.xxs,
  borderTopEndRadius: theme.scale(8),
  borderBottomEndRadius: theme.scale(6),
  padding: theme.sizes.xxs,
  borderRadius: 0,
  opacity: 0.8,
}))

export const CounterBtn = styled(Button)(({ theme, color }) => ({}))

export const CountText = styled.View(({ theme }) => ({
  marginHorizontal: theme.scale(10),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const Row = styled.View(({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: theme.colors.background1,
  margin: -theme.sizes.xxs,
  padding: theme.sizes.xxs,
  borderTopEndRadius: theme.scale(8),
  borderBottomEndRadius: theme.scale(6),
  opacity: 0.8,
}))
