import styled from '@emotion/native'
import Text from '../../atoms/Text'

export const PaymentOptionsContainer = styled.View(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.background0,
  paddingHorizontal: theme.sizes.sm,
  paddingTop: theme.sizes.lg,
  gap: theme.sizes.lg,
}))

export const CloseTouchable = styled.TouchableOpacity({
  position: 'absolute',
  right: 0,
})

export const PaymentItemContainer = styled.TouchableOpacity(({ theme }) => ({
  flex: 1,
  width: '100%',
  paddingVertical: theme.scale(10),
}))

export const IconWrapper = styled.View(({}) => ({
  width: '15%',
}))

export const PaymentItemRightContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const PaymentItemDataContainer = styled.View(() => ({
  flex: 1,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const Separator = styled.View<{ isEnd: boolean }>(({ theme , isEnd}) => ({
  width: '85%',
  borderBottomColor: '#6C6C6C',
  alignSelf: 'flex-end',
  opacity: 0.1,
  borderBottomWidth: isEnd ? 0 : 1,
  paddingTop: theme.sizes.md,
}))

export const TitleContainer = styled.View(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.background0,
  paddingBottom: theme.sizes.sm,
}))

export const SubtitleText = styled(Text)(({ theme }) => ({
  margin: 0,
  padding: 0,
}))

export const Header = styled.View(() => ({
  width: '100%',
}))

export const SwitchOption = styled.Switch({
  alignSelf: 'flex-end',
  transform: [{ scaleX: 1 }, { scaleY: 1 }],
})
