import styled from "@emotion/native"

const Container = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.sm,
  padding: theme.sizes.sm,
}))

const Avatar = styled.Image(({ theme }) => ({
  width: 70,
  height: 70,
  borderRadius: 35
}))

const DescriptionContainer = styled.View(({ theme }) => ({
  flexGrow: 2,
  flexShrink: 1,
  gap: 5,
}))

const ActionContainer = styled.View(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'flex-end',
  flexGrow: 1,
}))

const OrderActionContainer = styled.View(() => ({
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}))

export { Container, Avatar, DescriptionContainer, ActionContainer, OrderActionContainer }