import styled from "@emotion/native";

const Container = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.foreground4,
  paddingVertical: theme.sizes.sm 
}))

const TextContainer = styled.View(({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  gap: theme.sizes.sm,
  alignItems: 'center',
}))

const ValueContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
}))

export { Container, TextContainer, ValueContainer }