import styled from '@emotion/native'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../atoms/Text'
import { Animated, StyleSheet } from 'react-native'
import { Addon } from '../../atoms/Addon'
import Button from '../../molecules/Button'
import TextInput from '../../atoms/TextInput'

export const HeaderImage = styled.Image(() => ({
  aspectRatio: 16 / 9,
  width: '100%',
}))

export const Overlay = styled(LinearGradient)({
  ...StyleSheet.absoluteFillObject,
})

export const HeaderContainer = styled.View(({ theme }) => ({
  paddingTop: theme.sizes.sm,
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingRight: theme.sizes.sm,
}))

export const Description = styled(Text)(({ theme }) => ({
  paddingTop: theme.sizes.xxxs,
  paddingBottom: theme.sizes.sm,
}))

export const DishInfoContainer = styled(Animated.View)(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
  paddingTop: theme.sizes.sm,
  backgroundColor: theme.colors.background0,
  width: '100%',
}))

// List
export const Separator = styled.View(({ theme }) => ({
  height: theme.sizes.sm,
  width: '100%',
}))

export const AddOnContainer = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.background0,
}))

export const AddOnWrapper = styled(Addon)<{ isLast?: boolean }>(({ theme, isLast }) => ({
  marginBottom: isLast ? theme.sizes.sm : 0,
  marginHorizontal: theme.sizes.sm,
}))

export const SectionSeparator = styled.View(({ theme }) => ({
  height: 1,
  backgroundColor: '#6C6C6C',
  opacity: 0.1,
  marginHorizontal: theme.sizes.sm,
  marginTop: theme.sizes.xxxs,
}))

export const TitleContainer = styled.View(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
  backgroundColor: theme.colors.background0,
  paddingVertical: theme.sizes.xs,
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
}))

export const ExitButton = styled.TouchableOpacity(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: 40,
  height: 40,
}))

export const AddButton = styled(Button)(({ theme }) => ({
  paddingHorizontal: 16,
  paddingVertical: 5,
}))

export const InstructionsSection = styled.View(({ theme }) => ({
  display: 'flex',
  marginHorizontal: 16,
  marginVertical: 16,
  backgroundColor: theme.colors.background0,
  elevation: 2,
  borderRadius: theme.scale(8),
  /* shadowColor: '#000',
  shadowOffset: { width: 0, height: 4 },
  shadowOpacity: 0.3,
  shadowRadius: 5, */
  padding: theme.sizes.xxs,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const InstructionsInput = styled(TextInput)(({ theme }) => ({
  borderRadius: theme.scale(8),
  borderColor: '#4461A9',
  borderWidth: 1.5,
  padding: theme.sizes.xxs,
  zIndex: 10,
}))

export const CounterBtn = styled(Button)(({ theme, color }) => ({

}))

export const CountText = styled.View(({ theme }) => ({
  marginHorizontal: theme.scale(25),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
export const Row = styled.View(({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: theme.colors.background0,
  margin: theme.sizes.xxs,
  padding: theme.sizes.xxs,
  borderTopEndRadius: theme.scale(8),
  borderBottomEndRadius: theme.scale(6),
  opacity: 0.8,
}))
