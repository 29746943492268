import React from 'react'
import { StyleProp, TextProps, TextStyle } from 'react-native'
import { Colors } from '@eassen/themes'
import { StyledText, TextProps as StyledTextProps, textAlign } from './style'

export interface CustomTextProps extends StyledTextProps, TextProps {
  numberOfLines?: number
  style?: StyleProp<TextStyle>
  color?: keyof Colors
  children?: React.ReactNode
  textAlign?: textAlign
}

const Text: React.FC<CustomTextProps> = (props) => {
  return <StyledText {...props} />
}

export default Text
