"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lightColors = void 0;
exports.lightColors = {
    primary: '#0B317F',
    secondary: '#FF7F2A',
    tertiary: '#0A57F2',
    quaternary: '#3CC6ED',
    quinary: '#E6CF63',
    senary: '#007AFF',
    error: '#CA0519',
    success: '#4FA948',
    warning: '#E6CF63',
    info: '#007AFF',
    foreground0: '#FFFFFF',
    foreground1: '#9E9E9E',
    foreground2: '#A5A5A5',
    foreground3: '#666666',
    foreground4: '#AAAAAA',
    foreground5: '#FFFFFF',
    foreground6: '#9796A1',
    foreground7: '#19B300',
    background0: '#0C0C0C',
    background1: '#262626',
    background2: '#1C1C1E',
    background3: '#888888',
    background4: '#00000070',
    background5: 'rgba(0, 0, 0, 0.60)',
};
